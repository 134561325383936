<div class="document-viewer">
  <div *ngIf="loading" class="loading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>

  <div *ngIf="fileNotFound" class="file-not-found">
    <div class="file-not-found-content">
        <div class="file-not-found-content-icon">
            <fa-icon class="file-not-found-times-circle" icon="times-circle"></fa-icon>
            <fa-icon class="file-not-found-file"  icon="file" size="3x"></fa-icon>
        </div>
        <p>Documento não encontrado</p>
    </div>
  </div>

  <div *ngIf="!loading && !fileNotFound" class="download-message">
    <div class="download-content">
      <p>O download do seu documento começará automaticamente...</p>
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div> 