import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {
  public loading: boolean = true;
  public fileNotFound: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const documentId = params['fileId'];
      if(!documentId || documentId == 'exampleId') {
        this.fileNotFound = true;
        this.loading = false;
        return ;
      };
      this.processDocument(documentId);

    });
  }

  private processDocument(documentId: string): void {
    try {
      const [name, id] = documentId.split('_');
      if (!name || !id) {
        throw new Error('ID do documento inválido');
      }

      const url = `${environment.api_message}/download/${name}/${id}`;
      
      // Criar um elemento 'a' para forçar o download
      const link = document.createElement('a');
      link.href = url;
      link.download = documentId; // Nome do arquivo para download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      this.loading = false;
    } catch (error) {
      console.error('Erro ao processar documento:', error);
      this.fileNotFound = true;
      this.loading = false;
    }
  }
} 