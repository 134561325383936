import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SurveyControl, SurveyControlResponse } from '../models/survey-control.interface';
import { SurveyNpsDto } from '../shared/components/survey-nps/survey-nps.interface';

@Injectable({
  providedIn: 'root'
})
export class SurveyControlService {
  private apiUrl = `${environment.api}/survey-control`;

  constructor(private http: HttpClient) {}

  findOne(id: number): Observable<SurveyControl> {
    return this.http.get<SurveyControl>(`${this.apiUrl}/${id}`);
  }

  getSurveyNpsByScheduleId(scheduleId: number): Observable<SurveyControlResponse> {
    return this.http.get<any>(`${this.apiUrl}/survey-nps-teleconsultation/${scheduleId}`);
  }

  public createSurveyNps(surveyNpsDto:SurveyNpsDto): Observable<any> {
    if (!surveyNpsDto) throw new Error('"surveyNpsDto" is required');
    const url = `${this.apiUrl}/survey-nps/create`;
    return this.http.post(url, surveyNpsDto);
  }
}