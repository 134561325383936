<div class="wrapper">
    <div *ngIf="loading" class="loading">
        <mat-spinner class="custom"></mat-spinner>
    </div>
    
    <div *ngIf="!loading" class="confirmation">
        <img [src]="logo" class="imgLogo"/>
        <div *ngIf="loadingSteps" class="loading">
            <mat-spinner class="custom"></mat-spinner>
        </div>
        <div *ngIf="step === 1 && !loadingSteps" class="stepOne"> 
            <h3>Seja bem-vindo(a) à {{clinic.name}}, para registrar sua presença, por gentileza insira o seu CPF abaixo:</h3>
            <mat-form-field appearance="outline" class="cpfInput">
                <mat-label>CPF</mat-label>
                <input
                  matInput
                  type="text"
                  [formControl]="cpf"
                  [specialCharacters]="['/', '.', '-']"
                  [mask]="'000.000.000-00'"
                  required="true"
                />
            </mat-form-field>
    
    
        </div>

        <div *ngIf="step === 2 && !loadingSteps" class="stepTwo">
            <h3 *ngIf="!isNewPatient">Você possui os seguintes agendamentos. Deseja confirmar presença?</h3>
            <h3 *ngIf="isNewPatient">Não localizamos seu CPF, por favor clique no botão "Cadastrar" abaixo para efetuar seu cadastro</h3>
            <div class="scheduleList" *ngIf="schedules.descriptions.length > 0">
                <mat-list role="list">
                    <ng-container *ngFor="let description of schedules.descriptions" >
                        <mat-list-item role="listitem">— {{description}}</mat-list-item>
                    </ng-container>
                </mat-list>
            </div>
            <div class="noScheduleList" *ngIf="schedules.descriptions.length === 0">
                <fa-icon icon="times-circle" *ngIf="!isNewPatient"></fa-icon>
                <h3 *ngIf="!isNewPatient">Nenhum agendamento encontrado</h3>
                <button
                    *ngIf="isNewPatient"
                    mat-button
                    class="btn-secondary btn-large add"
                    (click)="addPatient()"
                >
                    Cadastrar
                </button>
            </div>

        </div>

        <div *ngIf="step === 3 && !loadingSteps" class="stepThree">
            <fa-icon [icon]="['far', 'check-circle']" size="5x" class="green"></fa-icon> 
            <h2>Obrigado pela confirmação, em breve você será chamado(a) para seu atendimento</h2>
        </div>

        <div class="actions">
            <button
                *ngIf="step === 1 && !loadingSteps"
                mat-button
                class="btn-secondary btn-large"
                (click)="checkDocument()"
            >
                Confirmar
            </button>
            <span *ngIf="step === 2 && !loadingSteps" class="actionsStepTwo">
                <button
                    mat-button
                    class="btn-secondary btn-large no"
                    (click)="cancelConfirmation()"
                >
                    <span *ngIf="schedules.descriptions.length === 0">
                        <fa-icon icon="arrow-left"></fa-icon> Voltar
                    </span>
                    <span *ngIf="schedules.descriptions.length > 0">
                        Não
                    </span>
                </button>
                <button
                    *ngIf="schedules.descriptions.length > 0"
                    mat-button
                    class="btn-secondary btn-large yes"
                    (click)="confirmSchedules()"
                >
                    Sim
                </button>
            </span>
            <div class="redirectTimer" *ngIf="step === 3 && !loadingSteps">
                <p class="redirectText">Redirecionando para tela inicial em</p>
                <div class="timer">
                    <mat-progress-spinner
                        class="progressSpinner"
                        diameter="30"
                        [mode]="mode"
                        [strokeWidth]="3"
                        [value]="progress"
                    >
                    </mat-progress-spinner>
                    <p class="timetTXT">{{timer}}</p>
                </div>
            </div>
        </div>
        
    </div>
</div>
