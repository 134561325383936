import {
  IProcedure,
  IExpertise,
  IModalidades,
} from 'src/app/shared/partials/agendamento/inputs/inputs.component.interface';
import { IPatientResponse } from '../perfil/perfil.interface';

export interface IAgendamento {}

export interface IAgendamentoResponse {
  agendaId: number;
  agendaDate: string;
  agendaHourStart: number;
  agendaHourEnd: number;
  clinicId: number;
  clinicName: string;
  procedureId: number;
  procedureName: string;
  professionalName: string;
  professionalOccupation: string;
  status: string;
  fullDate: number;
  removePending? :boolean;
  linkTeleconsulta? :string;
  healthPlanId? :number;
}

export interface IHourAvailabilityResponse  {
  date: string;
  values: IAgendamentoHorarioResponse[];
}

export interface IAgendamentoHorarioResponse {
  name: string;
  availableTimes: any;
  busyTimes: any;
  document: string;
  documentType: string;
  documentUf: string;
  occupation: string;
  title: string;
  gender: string;
  professional: string;
  photo: string;
  procedure: string;
  expertise: string;
  crm: string;
  schedules: string[];
  rqe: string;
  bio?: string;
  clinicTimes?: {startTime:string, endTime:string}[];
}

export interface IAgendamentoSearch {
  professionalId: number;
  professionalPhoto: string;
  professional: {
    id: number;
    name: string;
    photo: string;
  };
  price: string;
  occupation: {
    id: number;
    name: string;
    expertiseId?: number;
  };
  hour: {
    startTime: string | number;
    endTime: string | number;
    id?: string | number;
  };
  treatmentPronoun: string;
  gender: string;
  procedure: IProcedure;
  modalidade: IModalidades;
  expertise?: IExpertise;
  scheduleDate: string;
  clinicId: number;
  document: string;
  documentType: string;
  documentUf: string;
  rqe?: string;
  local?: any;
  lastCachedDay?: string;
  cachedResults?: IHourAvailabilityResponse[];
  crp?: string;
  preScheduledId?: number;
  agendaId?: number;
  voucherId?: number | null;
  reservedAt?: string;
  paciente?:IPatientResponse,
  healthPlanId?:number
  paymentLink?:string
  rescheduleId?: number;
}

export interface IAgendamentoDeletedResponse {
  status: number;
  message: string;
  error?: object;
}

export interface IAgendamentoDeletedRequest {
  id: number;
  reason: string;
  professionalId: number;
}

export interface IConfirmAgenda {
  token: string;
  accountId: number;
}

export interface INewScheduleQueryParams {
  expertise: string;
  local: string
  modalidade: string
  occupation: string
  procedure: string
  scheduleDate: string
}

export const freeProcedure:string = '0.00';
