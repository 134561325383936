import { IPatientResponse } from './../../../../pages/perfil/perfil.interface';
import { Component, Input, Output, EventEmitter, Inject, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { IAgendamentoSearch } from 'src/app/pages/agendamentos/agendamentos.interfaces';
import {
  IModalidades,
  IModalidadesFake,
  IOccupation,
  IExpertise,
  ILocal,
  IProcedure,
} from './inputs.component.interface';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { MSG } from 'src/app/util/constants';
import * as _ from 'lodash';
import { AmorSaudeService } from 'src/app/services/amor-saude.service';
import { SlackService } from 'src/app/services/slack.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/services/login.service';
import { AgendamentosService } from 'src/app/services/agendamentos.service';
import { CadastroService } from 'src/app/services/cadastro.service';
import { environment } from 'src/environments/environment';
import { ISchedulerResponse } from 'src/app/pages/perfil/scheduler.interface';

moment.locale('pt-br');

@Component({
  selector: 'agendamento-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss'],
})
export class InputsComponent implements OnInit, OnChanges {
  className = '';
  queryParams: Params = null;
  patientIdParam = null
  isAmorSaude = false;

  constructor(
    private companyService: CompanyService,
    private amorSaudeService: AmorSaudeService,
    private agendamentosService: AgendamentosService,
    private slackService: SlackService,
    private router: Router,
    private snack: MatSnackBar,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private cadastroService: CadastroService
    ) {
      this.patientInfo = LocalStorageUtil.getPatientInfo();
      this.schedulerInfo = LocalStorageUtil.getSchedulerInfo();
      this.account = LocalStorageUtil.getAccountInfo();
      this.isSchedulersView = loginService.isSchedulersView();
      if(!this.isSchedulersView)
        if(this.patientInfo)
          this.profile = this.patientInfo.permissions.profile;
 
      this.className = this.loginService.isAmorSaude()? 'amorsaude' : '';
      this.isAmorSaude = this.loginService.isAmorSaude() || false;
    }

    ngOnChanges(changes: SimpleChanges){
      if(changes.step.currentValue === 0 && !!changes.step.previousValue){
        this.form.reset();
        this.form.controls.scheduleDate.setValue(moment().toDate());
      }
    }

    ngOnInit(): void {

      if(!this.isSchedulersView)
        if(!this.account)
          this.cadastroService
          .loadAccount(environment.subdomain)
          .subscribe((account: AccountInfo) => {
            this.account = account;
            LocalStorageUtil.setAccountInfo(account);
            this.getModalidades();
          });
        else
          this.getModalidades();
      else
        this.configPatientInfo();

      this.route.queryParams.subscribe(p => {
        if(p.crp){
          this.form.controls.crp.setValue(p.crp)
          this.snack.open(
            'Preenchimento automático ativado, buscando informações pelo CRP',
            'Ok',
            { duration: 5000, panelClass: this.className }
          )
          this.doAutoFill = true  
        }

        if(p.modalidade){ 
          this.queryParams = p;
          
          if(p.reservedAt)
            this.search.reservedAt = p.reservedAt;

          this.snack.open(
            'Verificando informações selecionadas, aguarde',
            'Ok',
            { duration: 5000, panelClass: this.className }
          )
          this.doAutoFill = true
        }
      })
    }

  @Input() step: number;
  @Input() search: IAgendamentoSearch;
  @Input() professionalId: number = null;
  @Input() rescheduleId: number = null;
  @Output() stepChanged: EventEmitter<number> = new EventEmitter();
  @Output() dataChanged: EventEmitter<string> = new EventEmitter();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;

  patientInfo: IPatientResponse;
  schedulerInfo: ISchedulerResponse;
  isSchedulersView: boolean = false;
  account: AccountInfo;
  professionalHasOnlineConfiguration = true;

  public form: FormGroup = new FormGroup({
    modalidade: new FormControl('', Validators.required),
    occupation: new FormControl('', Validators.required),
    expertise: new FormControl('', Validators.required),
    procedure: new FormControl('', Validators.required),
    local: new FormControl('', Validators.required),
    scheduleDate: new FormControl(moment().toDate()),
    crp: new FormControl(null),
    paciente: new FormControl(null)
  });

  public modalidades: IModalidades[] | IModalidadesFake[] = [];
  public occupations: IOccupation[] = [];
  public expertiseId: string;
  public procedures: IProcedure[] = [];
  public locals: ILocal[] = [];
  public today = new Date();
  public isGettingApiResult = true;
  public isElegible = false;
  private snackBarRef;
  private unsubscribe$: Subject<void> = new Subject();
  private profile;
  private doAutoFill = false;
  public pacientes: IPatientResponse[] = [];

  private loadCurrentScheduler() {
    this.cadastroService
      .loadScheduler()
      .subscribe((_scheduler) => {
        LocalStorageUtil.setSchedulerInfo(_scheduler);
        this.schedulerInfo = _scheduler;
      });
  }

  public nextStep(currentStep?: number) {
    switch (currentStep || this.step) {
      case 1:
        this.checkElegibility();
        break;
      /*case 2:
        this.getExpertise();
        break;*/
      case 2:
        this.expertiseId = this.form.value.occupation.expertiseId ? this.form.value.occupation.expertiseId.toString() : null;
        this.getProcedure();
        break;
      case 3:
        this.search.healthPlanId = this.form.value.procedure?.healthPlanId || null;
        this.getLocal();
        break;
      case 4:
        this.showSchedule();
        break;
    }

    this.search.cachedResults = [];
    this.step = currentStep ? ++currentStep : ++this.step;

    let data: any;
    if(this.search?.reservedAt)
      data = { ...this.form.value, reservedAt: this.search.reservedAt};
    else
      data = this.form.value;

    if(this.form.value?.procedure)
      data.healthPlanId = this.form.value.procedure?.healthPlanId
    
    this.dataChanged.emit(data);
    this.stepChanged.emit(this.step);
  }

  private async configPatientInfo(){
    this.route.queryParams.subscribe(p => {
      if(p.patientId) {
        this.patientIdParam = p.patientId
      }
    })

    if(!this.patientIdParam){
      this.isGettingApiResult = false;
      return;
    }
    
    this.isGettingApiResult = true;
    this.agendamentosService.findPatientsByInfo({patientId: this.patientIdParam}).subscribe(
      res =>{
        this.isGettingApiResult = false;
        this.pacientes = res.data;

        this.form.controls.paciente.setValue(this.pacientes[0])
        this.getPatientModalidades()
      },
      err =>{
        this.isGettingApiResult = false;
        this.snack.open('Erro ao carregar pacientes, tente novamente', 'ok', { duration: 3000 });
      }
    )
  }

  public async getPatientHealthPlans(){

    this.isGettingApiResult = true;
    const patient = this.form.getRawValue().paciente;
    this.agendamentosService.findPatientsHealthPlans(patient.id).subscribe(
      (res) =>{
        this.isGettingApiResult = false;

        const info = res.data
        this.form.controls.paciente.setValue({ ...patient, ...info });

        this.getPatientModalidades();
      },
      err =>{
        this.isGettingApiResult = false;
        this.snack.open('Erro ao carregar pacientes, tente novamente', 'ok', { duration: 3000 });
      }
    )
  }

  private async checkElegibility(){
    this.form.value.modalidade.fake? this.showHealthPlanAdvice() : this.getOccupation();  
  }

  public async getPatientModalidades() {
    this.isGettingApiResult = true;
    await this.resetValues();

    this.patientInfo = this.form.value.paciente;
    this.profile = this.patientInfo.permissions.profile;

    const ids = _.uniq(
      _.map(this.patientInfo.healthPlans, (i) => i.planId),
    ).join(',');

    this.companyService
      .getModalidades(ids, this.professionalId, this.isSchedulersView, this.patientInfo.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        async (modalidades: any[]) => {
          if(this.step === 0)
            this.step++;
          
          if (await this.shoulAddFakeHealthplan(modalidades)) {
            modalidades.push(this.getFakeHealthPlan());
          }

          await this.handleGetModalidadesResponse(modalidades, this.patientIdParam);
        },
        (error) => {
          this.handleGetModalidadesError();
        },
      );

  }

  private async resetValues(): Promise<void>{
    this.step = 1;
    this.modalidades = [];
    this.occupations= [];
    this.expertiseId = null;
    this.procedures= [];
    this.locals= [];
  }
  
  private async getModalidades() {
    this.isGettingApiResult = true;

    if(this.professionalId){
      this.professionalHasOnlineConfiguration = await this.companyService.checkProfessionalOnlineConfig(this.professionalId, this.account.accountId).toPromise().then(res => res);

      if(!this.professionalHasOnlineConfiguration)
        return;
    }

    if(this.patientInfo != null){
      const ids = _.uniq(
        _.map(this.patientInfo.healthPlans, (i) => i.planId),
      ).join(',');
      this.companyService
        .getModalidades(ids, this.professionalId, this.isSchedulersView)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          async (modalidades: any[]) => {
            if (await this.shoulAddFakeHealthplan(modalidades)) {
              modalidades.push(this.getFakeHealthPlan());
            }

            await this.handleGetModalidadesResponse(modalidades, null);
          },
          (error) => {
            this.handleGetModalidadesError();
          },
        );
    } else {
      this.companyService
        .getModalidadesConta(this.account.accountId, this.professionalId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          async (modalidades: any[]) => {
            await this.handleGetModalidadesResponse(modalidades, null);
          },
          (error) => {
            this.handleGetModalidadesError();
          },
        );
    }

  }

  private async handleGetModalidadesResponse(modalidades: any[], patientIdParam?: number){
    try {
      if(patientIdParam || this.loginService.isOmint()) {
        // Tratativa específica para não exibir o tipo 'Particular' para pacientes Omint (Disney)
        this.modalidades = modalidades?.filter(m => m.name != 'Particular')
      } else if (this.isAmorSaude) {
        // Tratativa específica para exibir 'Cartão de Todos' ao invés de 'Particular' para pacientes Amor Saúde
        this.modalidades = modalidades?.map(m => {
          if(m.name == 'Particular') m.name = 'Cartão de Todos';
          return m;
        })
      } else {
        this.modalidades = modalidades;
      }
  
      this.isGettingApiResult = false;
  
      this.checkAutoFill(this.modalidades, this.form.controls.modalidade, 'modalidade')
    } catch (error) {
      this.handleGetModalidadesError();
    }
  }

  private handleGetModalidadesError(){
    this.modalidades = [this.getFakeHealthPlan()];
    this.isGettingApiResult = false;
  }

  public getOccupation() {
    this.isGettingApiResult = true;
    if(this.patientInfo != null){
      let procedures;
  
      if(this.queryParams?.procedure && typeof this.queryParams.procedure == 'object')
        procedures = this.queryParams.procedure.join(',');
      else if(this.queryParams?.procedure && typeof this.queryParams.procedure == 'string')
        procedures = this.queryParams.procedure;
      else
       procedures = this.form.value.modalidade.procedureIds;

      const patientId = this.isSchedulersView ? this.patientInfo.id : null

      this.companyService
        .getOccupation(procedures, this.professionalId, this.isSchedulersView, patientId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((occupations) => {
          this.occupations = occupations;

          occupations.forEach(element => {
            if(element.expertise) {
              element.name = element.name + ' - ' +  element.expertise;
            }
          });

          this.isGettingApiResult = false;

          if(this.professionalId){
            this.form.controls.occupation.setValue(this.occupations[0]);
            this.nextStep(2);
            return;
          }

          this.checkAutoFill(this.occupations, this.form.controls.occupation, 'occupation')
        });
    }else{
      this.companyService
      .getAccountOccupation(this.account.accountId,this.form.value.modalidade.procedureIds, this.professionalId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((occupations) => {
        this.occupations = occupations;

        occupations.forEach(element => {
          if(element.expertise) {
            element.name = element.name + ' - ' +  element.expertise;
          }
        });

        if(this.professionalId){
          this.form.controls.occupation.setValue(this.occupations[0]);
          this.nextStep(2);
          return;
        }
        
        this.isGettingApiResult = false;

        this.checkAutoFill(this.occupations, this.form.controls.occupation)
      });
    }
  }

  public async getProcedure() {
    this.isGettingApiResult = true;

    let agenda = null;
    if (this.rescheduleId) {
      agenda = await this.agendamentosService.getAgendamentoById(this.rescheduleId).toPromise();
    }

    if(this.patientInfo !== null){
      const patientId = this.isSchedulersView ? this.patientInfo.id : null

      this.companyService
        .getProcedure(
          this.expertiseId,
          this.form.value.occupation.id,
          this.form.value.modalidade.id,
          this.professionalId,
          this.isSchedulersView,
          patientId
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((procedures) => {
          this.procedures = agenda ? procedures.filter(p => p.id === agenda.procedureId) : procedures;
          this.isGettingApiResult = false;
  
          this.checkAutoFill(this.procedures, this.form.controls.procedure, 'procedure')
        });
      }else{
        this.companyService
          .getAccountProcedure(
            this.account.accountId,
            this.expertiseId,
            this.form.value.occupation.id,
            this.form.value.modalidade.id,
            this.professionalId
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((procedures) => {
            this.procedures = agenda ? procedures.filter(p =>  p.id === agenda.procedureId) : procedures;
            this.isGettingApiResult = false;
    
            this.checkAutoFill(this.procedures, this.form.controls.procedure)
          });
    }
  }

  public getLocal() {
    this.isGettingApiResult = true;
    if(this.patientInfo !== null){
      this.companyService
        .getLocal(
          this.expertiseId,
          this.form.value.occupation.id,
          this.form.value.procedure.id,
          this.professionalId,
          this.isSchedulersView
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((locals) => {
          this.locals = locals;
          this.isGettingApiResult = false;
  
          this.checkAutoFill(this.locals, this.form.controls.local, 'local')
        });
      }else{
        this.companyService
          .getAccountLocal(
            this.account.accountId,
            this.expertiseId,
            this.form.value.occupation.id,
            this.form.value.procedure.id,
            this.professionalId
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((locals) => {
            this.locals = locals;
            this.isGettingApiResult = false;
    
            this.checkAutoFill(this.locals, this.form.controls.local)
          });

    }
  }

  public showSchedule() {
    const data = this.form.value;
    const date = this.form.value.scheduleDate;
    data.scheduleDate = moment(date).format('DDMMYYYY');

    this.dataChanged.emit(data);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getFakeHealthPlan() {
    return { fake: true, name: 'Convênio' };
  }

  private async shoulAddFakeHealthplan(modalidades) {
    let hasAgendaOnlineProcedure;
    const emptyResults =
      !modalidades.length ||
      (modalidades.length <= 1 && modalidades[0].id === -1);
    const canAdd = this.profile ? this.profile.healthplan.add : false;

    if (emptyResults && canAdd) {
      hasAgendaOnlineProcedure = await this.companyService.hasAgendaOnlineProcedure(this.isSchedulersView);
      if (hasAgendaOnlineProcedure && this.patientInfo.healthPlans.length) {
        console.info(
          'Seu convênio não tem procedimentos com agenda online ativada',
        );
      }

      if (!hasAgendaOnlineProcedure) {
        console.info('Não existem procedimentos com agenda online ativada');
      }
    }

    return (
      emptyResults &&
      canAdd &&
      hasAgendaOnlineProcedure &&
      !this.patientInfo.healthPlans.length
    );
  }

  private showHealthPlanAdvice() {
    if (sessionStorage.getItem('dont-want-add-healthplan')) return;

    if (this.profile && this.profile.healthplan.add !== 1) {
      this.snack.open(MSG.ADD_SCHEDULE.CANT_ADD_SCHEDULE, 'Ok', {
        duration: 3000,
        panelClass: this.className
      });
      return;
    }

    if (!this.patientInfo.healthPlans.length) {
      this.snackBarRef = this.snack.openFromComponent(SnackBarTemplate);
      Object.assign(this.snackBarRef, {
        closeWithAction: () => {
          this.router.navigate(['/perfil'], {
            queryParamsHandling: 'preserve',
          });
          sessionStorage.setItem('dont-want-add-healthplan', '1');
          this.snackBarRef.dismiss();
        },
        dismissWithAction: () => {
          sessionStorage.setItem('dont-want-add-healthplan', '1');
          this.snackBarRef.dismiss();
        },
      });
    } else {
      console.info('Adicione um convênio com agenda online ao seu perfil ');
    }
  }

  private checkAutoFill(arr: Array<any>, formField: AbstractControl, formName?:string){
    
    if(this.queryParams != null){
      const paramId = parseInt(this.queryParams[formName]); 
      const value = arr.find(el =>{ 
        if(formName == 'occupation')
          return el.id == paramId && el.expertiseId == this.queryParams['expertiseId'];
        else
          return el.id == paramId;
      });

      if(value){
        formField.setValue(value);
        this.nextStep()
      }else{
        if(formName == 'modalidade'){
          this.snack.open(
            'Você não possui o tipo de convênio selecionado. Por favor, faça o cadastro em seus dados cadastrais pelo link ao lado',
            'Dados cadastrais',
            { duration: 15000 }
          );
          this.snack._openedSnackBarRef.onAction().subscribe(() => {
            this.router.navigate(['perfil']);
          });
        } else{
          if(!this.queryParams.voucher)
            this.snack.open(
              'A consulta que você estava tentando agendar não pertence ao seu convênio atual. Por favor, seleciona um tipo de atendimento particular ou do seu convênio',
              'ok',
              { duration: 8000 }
            );
        }
      } 

    } else if((arr.length == 1 || this.doAutoFill) && this.queryParams == null){
      formField.setValue(arr[0])
      this.nextStep()
    }
  }
}
@Component({
  selector: 'snackbar-template',
  templateUrl: `inputs.snackbar.template.html`,
})
export class SnackBarTemplate {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarTemplate>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
