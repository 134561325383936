<div class="consent-dialog">
  <div class="consent-dialog__header">
    <h2 mat-dialog-title>Termo de Consentimento</h2>
  </div>

  <div mat-dialog-content>
    <div [innerHTML]="consentText"></div>
  </div>

  <div mat-dialog-actions class="consent-dialog__actions">
    <button mat-button class="consent-dialog__actions-close" (click)="onClose()">Negar</button>
    <button mat-button class="consent-dialog__actions-accept" (click)="onAccept()">Aceitar</button>
  </div>
</div> 