<!-- <div align="center">
    <button
        mat-button
        class="btn-refresh"
        (click)="refreshScreen()"
    >
        Voltar para a chamada
    </button>
</div> -->

<div *ngIf="loading" class="loading">
    <mat-spinner class="purple" diameter="80"></mat-spinner>
</div>

<div *ngIf="showExampleNps">
    <ng-container *ngTemplateOutlet="step_thanks;"></ng-container>
</div>


<main *ngIf="!loading && showNps">
    <app-survey-box
        (close)="closeSurvey()"
        (back)="backSurvey()"
        [showCloseButton]="showConfig.buttons.close"
        [showBackButton]="showConfig.buttons.back"
    >
        <div class="survey-nps">
            <div *ngIf="step == 1" @stepTransition>
                <ng-container *ngTemplateOutlet="step_score; context: {title: steps[1].title}"></ng-container>
            </div>
    
            <div *ngIf="step == 2 || step == 3 || step == 4" @stepTransition>
                <ng-container *ngTemplateOutlet="step_textarea; context: {title: steps[step].title}"></ng-container>
            </div>
    
            <div *ngIf="step == 5" @stepTransition>
                <ng-container *ngTemplateOutlet="step_thanks"></ng-container>
            </div>
        </div>
    </app-survey-box>
</main>

<!-- ----- TEMPLATE ------------------------------- -->
<ng-template #step_score let-title="title" >
    <div class="step">
        <p class="step-title"> {{ title }} </p>

        <div class="step-score">
            <div class="step-score-button-container">
                <button
                    *ngFor="let score of scoreList"
                    mat-mini-fab
                    class="step-score-item"
                    (click)="selectScore(score)"
                >
                    {{ score }}
                </button>
            </div>

            <div class="step-score-subtitle">
                <span class="step-score-subtitle-item"> 0 - Pouco provável </span>
                <span class="step-score-subtitle-item"> 10 - Muito provável </span>
            </div>
        </div>
    </div>
</ng-template>

<!-- ----- TEMPLATE ------------------------------- -->
<ng-template #step_textarea let-title="title" >
    <div class="step">
        <p class="step-title"> {{ title }} </p>

        <div class="step-textarea" [formGroup]="form">
            <textarea
                placeholder="Deixe sua sugestão"
                formControlName="comment"
            ></textarea>
        </div>

        <div class="step-send">
            <button
                mat-button
                (click)="create()"
            >
                Enviar avaliação
            </button>
        </div>
    </div>
</ng-template>

<!-- ----- TEMPLATE ------------------------------- -->
<ng-template #step_thanks>
    <div class="step">
        <div class="step-title thanks-message">
            <p> <span class="emoji">😀 </span> Obrigado pela sua resposta!</p>
            <p>Sua opinião é muito importante para a gente.</p>
        </div>
    </div>
</ng-template> 