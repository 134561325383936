import { ActivatedRoute, Router } from '@angular/router';
import { ILoginResponse } from './login.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { LoginService } from './../../services/login.service';
import { Component, OnInit } from '@angular/core';

import { Views } from '../views';

import { FormBuilder } from '@angular/forms';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { Subject } from 'rxjs';
import { CadastroService } from 'src/app/services/cadastro.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Views implements OnInit {
  private unsubscribe$: Subject<void> = new Subject();
  public loging: boolean;
  public urlParams
  public isAmorSaude = false
  public isSchedulersView = false;
  public isAllowedToRegister = true;
  public className: string = ''

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private snack: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private cadastroService: CadastroService,
  ) {
    super();
    this.buildForm();
    this.isAmorSaude = loginService.isAmorSaude();
    this.isSchedulersView = loginService.isSchedulersView();
    this.isAllowedToRegister = !loginService.noRegister();
    this.className = this.isAmorSaude? 'amorsaude' : '';
  }

  public ngOnInit() {
    this.initForm(this.fb);

    LocalStorageUtil.logout()

    const accountInfo = LocalStorageUtil.getAccountInfo();

    this.route.queryParams.subscribe(p => {
      this.urlParams = p
    })

    this.loading = true

    if (accountInfo) {
      this.logo = accountInfo.logo ? accountInfo.logo : '';
      this.loading = false;
    }

    this.cadastroService.accountChanged$.subscribe(account => {
      if (this.loading) {
        this.logo = account.logo ? account.logo : '';
        this.loading = false;
      }

      if(this.route.snapshot.params['sso']) {
        const sso = this.route.snapshot.params['sso']
        this.snack.open('Efetuando login automático', null, {
          duration: 5000
        })
        this.loging = true

        this.loginService.loginSSO({
          sso,
          accountId: account.accountId,
          isSchedulersView: 'true'
        }).subscribe(r => {
          this.snack.open('Login automático efetuado com sucesso!', null, {
            duration: 5000
          })
          
          this.onSuccessLogin(r)
        }, (error) => {
          this.snack.open('Erro ao efetuar login automático', null, {
            duration: 5000
          })

          this.loging = false
        })
      }
    });
  }
  
  private buildForm() {
    this.campos = {
      id: [],
      password: [],
    };
  }

  private onSuccessLogin(resp: ILoginResponse){
    this.loging = false;
    LocalStorageUtil.setToken(resp.accessToken);
    if("patient" in resp) {
      LocalStorageUtil.setPatientInfo(resp.patient);
      localStorage.removeItem('scheduler-info')
    }
    
    if("scheduler" in resp) {
      LocalStorageUtil.setSchedulerInfo(resp.scheduler);
      localStorage.removeItem('patient-info')
    }
    
    sessionStorage.removeItem('dont-disturb');

    this.loginService.connectSentry()

    if(this.urlParams.crp || this.urlParams.modalidade){
      this.router.navigate(['/agendamentos/adicionar'], {
        queryParamsHandling: 'preserve',
      });
    } else {
      if(this.isSchedulersView){
        if(this.route.snapshot.params['patient']) {
          const queryParams = {};

          queryParams['patientId'] = this.route.snapshot.params['patient'];

          if(!isNaN(parseInt(this.route.snapshot.params['reschedule'])))
            queryParams['rescheduleId'] = this.route.snapshot.params['reschedule'];
      
          this.router.navigate(['/agendadores/agendamentos/adicionar'], {
            queryParams
          });
        } else {
          this.router.navigate(['/agendadores/agendamentos/adicionar']);
        }
      }
      else
        this.router.navigate(['/agendamentos'], {
          queryParamsHandling: 'preserve',
        });
    }
  }
  
  public login() {
    this.loging = true;
    const account = LocalStorageUtil.getAccountInfo();
    this.loginService
      .login(this.fg.value, account.accountId, this.isSchedulersView)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: ILoginResponse) => {
          this.onSuccessLogin(resp)
        },
        (err) => {
          this.snack.open('Usuário ou senha inválido', '', { duration: 3000, panelClass: this.className });
          this.loging = false;
        },
      );
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
