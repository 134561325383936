<div class="card-horario">
  <mat-card class="card-horario-item">
    <div class="p-display-flex">
      <div class="card-horario-item-avatar">
        <img *ngIf="horario.photo" width="90" height="90" [src]="horario.photo" [alt]="horario.name"
          (error)="photoError(horario)" />

        <app-avatar *ngIf="!horario.photo" [info]="horario.name" [size]="'large'" [ngClass]="isAmorSaude? 'amorsaude-bg-red' : 'bg-purple'"></app-avatar>
      </div>

      <div class="card-horario-item-description">
        <div class="card-horario-item-description-name">
          {{ horario.title }} {{ horario.name }}
        </div>
        <div>
          {{ horario.occupation }}
          <span *ngIf="horario.expertise">({{ horario.expertise }})</span>
        </div>
        <div [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'" *ngIf="horario.document">
          {{ horario.documentType }}{{ horario.documentUf? '/'+horario.documentUf: ''}}
          {{ horario.document }}
          <span *ngIf="horario.rqe"> RQE {{ horario.rqe }}</span>
        </div>
        <div *ngIf="horario.bio && horario.bio.length > 0">
          <span *ngIf="partialBio"> {{ horario.bio | partial: 50 }} </span>
          <span *ngIf="!partialBio"> {{ horario.bio }} </span>
          <span *ngIf="horario.bio.length > 50" (click)="bioView()" class="showMoreOrLess"> {{expandBioTitle}} </span>
        </div>
      </div>
    </div>

    <div>
      <div class="card-horario-item-result">
        <button *ngFor="let hora of horario?.availableTimes" mat-button
          [ngClass]="isAmorSaude? 'btn-amorSaudeSecondary card-horario-item-result-btn' : 'btn-primary card-horario-item-result-btn'"
          (click)="onSelectHorario(horario, hora)">
          {{ hora.startTime | date: 'HH:mm':'HHmm' }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
