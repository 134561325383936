import { Views } from 'src/app/pages/views';
import { Component } from '@angular/core';
import { CadastroService } from './services/cadastro.service';
import { environment } from 'src/environments/environment';
import { LocalStorageUtil, AccountInfo } from './util/local-storage-util';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  public update: boolean = false;
  public version

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public cadastroService: CadastroService,
    private views: Views,
    private snack: MatSnackBar,
    private loginService: LoginService
  ) {
    this.findSubdomain();

    this.loginService.connectSentry()

    if(window.location.host.indexOf('agendarcc') > -1){
      this.version = 'V1-staging'
    }

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) =>{ 
        
        const isSchedulersView = !!event.url.match('agendadores');
        LocalStorageUtil.setSchedulers(isSchedulersView);
        if(isSchedulersView){
          const patientInfo = LocalStorageUtil.getPatientInfo();
          if(patientInfo)
            localStorage.removeItem('patient-info')
        }

        this.checkLogin(event.url)
      });
    
    this.cadastroService
      .loadAccount(environment.subdomain)
      .subscribe((account: AccountInfo) => {
        this.cadastroService.accountChangedSource.next(account);
        LocalStorageUtil.setAccountInfo(account);
      }, (err: {
        status: number
      }) => {
        if(err.status == 404){
          this.snack.open('Agenda não encontrada, confira o endereço da página, por favor', 'OK')
          this.router.navigate(['/404'])
        } else {
          this.snack.open('Erro desconhecido ao procurar conta, atualize a página, por favor', 'OK')
        }
      });
  }

  private checkLogin(url) {
    if (url === '/login' && !!LocalStorageUtil.getToken()) {
      this.router.navigate(['/agendamentos'], {
        queryParamsHandling: 'preserve',
      });
      return;
    }
    
    if(
      url.match('perfil/') || 
      url.match('agendadores') || 
      url.match('sala-espera/') || 
      url.match('pesquisa-nps') || 
      url.match('document/')
    ){
      this.router.navigate([url], { queryParamsHandling: 'preserve' });
      return;
    }

    const reg = /\/(login|cadastro|ativar\-email|redefinir\-senha|confirmar\-consulta|esqueci\-minha\-senha|404|pagar)/gi;
    if (!reg.test(url) && !LocalStorageUtil.getToken()) {
      if (this.views.getIsOnIframe()) {
        const token = this.route.snapshot.queryParamMap.get('token');
        if (token != null) {
          LocalStorageUtil.setToken(token);
          this.router.navigate(['/agendamentos'], { queryParams: {} });
        }
      } else {
        this.router.navigate(['/agendamentos/adicionar'], { queryParamsHandling: 'preserve' });
        return;
      }
    }
  }

  private findSubdomain() {
    const href = window.location.href;
    if (!href.includes('localhost')) {
      const subdomain = href.substring(
        href.lastIndexOf('//') + 2,
        href.indexOf('.'),
      );
      environment.subdomain = subdomain;
    }
    if (href.indexOf('unisrstg') >= 0) {
      environment.api =
        'https://agenda-online-api-unimedsr-staging.communicare.med.br/v1';
    }
    if (href.indexOf('unimedsr') >= 0) {
      environment.api =
        'https://agenda-online-api-unimedsr.communicare.med.br/v1';
    }
  }
}
