import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() scale: string;
  @Input() type: string;
  @Input() timeText: string = null;
  @Input() isAmorSaude: boolean = false;

}
