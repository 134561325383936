/*!
 *  @preserve
 *
 * ++++++++++++++++++++++++++++++++++++++
 * Add to Calendar TimeZones iCal Library
 * ++++++++++++++++++++++++++++++++++++++
 *
 * Version: 1.7.0
 * Creator: Jens Kuerschner (https://jenskuerschner.de)
 * Project: https://github.com/add2cal/timezones-ical-library
 * License: Apache-2.0
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tzlibVersion = '1.7.0';
const tzlibZonesDB = {
  Africa: {
    Abidjan: ['', 0],
    Accra: ['Africa/Abidjan', 0],
    Addis_Ababa: ['Africa/Nairobi', 1],
    Algiers: ['', 2],
    Asmara: ['Africa/Nairobi', 1],
    Asmera: ['Africa/Nairobi', 1],
    Bamako: ['Africa/Abidjan', 0],
    Bangui: ['Africa/Lagos', 3],
    Banjul: ['Africa/Abidjan', 0],
    Bissau: ['', 0],
    Blantyre: ['Africa/Maputo', 4],
    Brazzaville: ['Africa/Lagos', 3],
    Bujumbura: ['Africa/Maputo', 4],
    Cairo: ['', 5],
    Casablanca: ['', 6],
    Ceuta: ['', 7],
    Conakry: ['Africa/Abidjan', 0],
    Dakar: ['Africa/Abidjan', 0],
    Dar_es_Salaam: ['Africa/Nairobi', 1],
    Djibouti: ['Africa/Nairobi', 1],
    Douala: ['Africa/Lagos', 3],
    El_Aaiun: ['', 6],
    Freetown: ['Africa/Abidjan', 0],
    Gaborone: ['Africa/Maputo', 4],
    Harare: ['Africa/Maputo', 4],
    Johannesburg: ['', 8],
    Juba: ['', 4],
    Kampala: ['Africa/Nairobi', 1],
    Khartoum: ['', 4],
    Kigali: ['Africa/Maputo', 4],
    Kinshasa: ['Africa/Lagos', 3],
    Lagos: ['', 3],
    Libreville: ['Africa/Lagos', 3],
    Lome: ['Africa/Abidjan', 0],
    Luanda: ['Africa/Lagos', 3],
    Lubumbashi: ['Africa/Maputo', 4],
    Lusaka: ['Africa/Maputo', 4],
    Malabo: ['Africa/Lagos', 3],
    Maputo: ['', 4],
    Maseru: ['Africa/Johannesburg', 8],
    Mbabane: ['Africa/Johannesburg', 8],
    Mogadishu: ['Africa/Nairobi', 1],
    Monrovia: ['', 0],
    Nairobi: ['', 1],
    Ndjamena: ['', 3],
    Niamey: ['Africa/Lagos', 3],
    Nouakchott: ['Africa/Abidjan', 0],
    Ouagadougou: ['Africa/Abidjan', 0],
    'Porto-Novo': ['Africa/Lagos', 3],
    Sao_Tome: ['', 0],
    Timbuktu: ['Africa/Abidjan', 0],
    Tripoli: ['', 9],
    Tunis: ['', 2],
    Windhoek: ['', 4],
  },
  America: {
    Adak: ['', 10],
    Anchorage: ['', 11],
    Anguilla: ['America/Puerto_Rico', 12],
    Antigua: ['America/Puerto_Rico', 12],
    Araguaina: ['', 13],
    Argentina: {
      Buenos_Aires: ['', 13],
      Catamarca: ['', 13],
      ComodRivadavia: ['America/Argentina/Catamarca', 13],
      Cordoba: ['', 13],
      Jujuy: ['', 13],
      La_Rioja: ['', 13],
      Mendoza: ['', 13],
      Rio_Gallegos: ['', 13],
      Salta: ['', 13],
      San_Juan: ['', 13],
      San_Luis: ['', 13],
      Tucuman: ['', 13],
      Ushuaia: ['', 13],
    },
    Aruba: ['America/Puerto_Rico', 12],
    Asuncion: ['', 14],
    Atikokan: ['America/Panama', 15],
    Atka: ['America/Adak', 10],
    Bahia_Banderas: ['', 16],
    Bahia: ['', 13],
    Barbados: ['', 12],
    Belem: ['', 13],
    Belize: ['', 16],
    'Blanc-Sablon': ['America/Puerto_Rico', 12],
    Boa_Vista: ['', 17],
    Bogota: ['', 18],
    Boise: ['', 19],
    Buenos_Aires: ['America/Argentina/Buenos_Aires', 13],
    Cambridge_Bay: ['', 19],
    Campo_Grande: ['', 17],
    Cancun: ['', 15],
    Caracas: ['', 17],
    Catamarca: ['America/Argentina/Catamarca', 13],
    Cayenne: ['', 13],
    Cayman: ['America/Panama', 15],
    Chicago: ['', 20],
    Chihuahua: ['', 16],
    Ciudad_Juarez: ['', 19],
    Coral_Harbour: ['America/Panama', 15],
    Cordoba: ['America/Argentina/Cordoba', 13],
    Costa_Rica: ['', 16],
    Creston: ['America/Phoenix', 21],
    Cuiaba: ['', 17],
    Curacao: ['America/Puerto_Rico', 12],
    Danmarkshavn: ['', 0],
    Dawson_Creek: ['', 21],
    Dawson: ['', 21],
    Denver: ['', 19],
    Detroit: ['', 22],
    Dominica: ['America/Puerto_Rico', 12],
    Edmonton: ['', 19],
    Eirunepe: ['', 18],
    El_Salvador: ['', 16],
    Ensenada: ['America/Tijuana', 23],
    Fort_Nelson: ['', 21],
    Fort_Wayne: ['America/Indiana/Indianapolis', 22],
    Fortaleza: ['', 13],
    Glace_Bay: ['', 24],
    Godthab: ['America/Nuuk', 25],
    Goose_Bay: ['', 26],
    Grand_Turk: ['', 27],
    Grenada: ['America/Puerto_Rico', 12],
    Guadeloupe: ['America/Puerto_Rico', 12],
    Guatemala: ['', 16],
    Guayaquil: ['', 18],
    Guyana: ['', 17],
    Halifax: ['', 24],
    Havana: ['', 28],
    Hermosillo: ['', 21],
    Indiana: {
      Indianapolis: ['', 22],
      Knox: ['', 20],
      Marengo: ['', 22],
      Petersburg: ['', 22],
      Tell_City: ['', 20],
      Vevay: ['', 22],
      Vincennes: ['', 22],
      Winamac: ['', 27],
    },
    Indianapolis: ['America/Indiana/Indianapolis', 22],
    Inuvik: ['', 19],
    Iqaluit: ['', 22],
    Jamaica: ['', 15],
    Jujuy: ['America/Argentina/Jujuy', 13],
    Juneau: ['', 11],
    Kentucky: { Louisville: ['', 22], Monticello: ['', 22] },
    Knox_IN: ['America/Indiana/Knox', 20],
    Kralendijk: ['America/Puerto_Rico', 12],
    La_Paz: ['', 17],
    Lima: ['', 18],
    Los_Angeles: ['', 23],
    Louisville: ['America/Kentucky/Louisville', 22],
    Lower_Princes: ['America/Puerto_Rico', 12],
    Maceio: ['', 13],
    Managua: ['', 16],
    Manaus: ['', 17],
    Marigot: ['America/Puerto_Rico', 12],
    Martinique: ['', 12],
    Matamoros: ['', 20],
    Mazatlan: ['', 21],
    Mendoza: ['America/Argentina/Mendoza', 13],
    Menominee: ['', 20],
    Merida: ['', 16],
    Metlakatla: ['', 11],
    Mexico_City: ['', 16],
    Miquelon: ['', 29],
    Moncton: ['', 24],
    Monterrey: ['', 16],
    Montevideo: ['', 13],
    Montreal: ['America/Toronto', 22],
    Montserrat: ['America/Puerto_Rico', 12],
    Nassau: ['America/Toronto', 22],
    New_York: ['', 22],
    Nipigon: ['America/Toronto', 22],
    Nome: ['', 11],
    Noronha: ['', 30],
    North_Dakota: { Beulah: ['', 20], Center: ['', 20], New_Salem: ['', 20] },
    Nuuk: ['', 25],
    Ojinaga: ['', 20],
    Panama: ['', 15],
    Pangnirtung: ['America/Iqaluit', 22],
    Paramaribo: ['', 13],
    Phoenix: ['', 21],
    Port_of_Spain: ['America/Puerto_Rico', 12],
    'Port-au-Prince': ['', 22],
    Porto_Acre: ['America/Rio_Branco', 18],
    Porto_Velho: ['', 17],
    Puerto_Rico: ['', 12],
    Punta_Arenas: ['', 13],
    Rainy_River: ['America/Winnipeg', 20],
    Rankin_Inlet: ['', 20],
    Recife: ['', 13],
    Regina: ['', 16],
    Resolute: ['', 31],
    Rio_Branco: ['', 18],
    Rosario: ['America/Argentina/Cordoba', 13],
    Santa_Isabel: ['America/Tijuana', 23],
    Santarem: ['', 13],
    Santiago: ['', 32],
    Santo_Domingo: ['', 12],
    Sao_Paulo: ['', 13],
    Scoresbysund: ['', 33],
    Shiprock: ['America/Denver', 19],
    Sitka: ['', 11],
    St_Barthelemy: ['America/Puerto_Rico', 12],
    St_Johns: ['', 34],
    St_Kitts: ['America/Puerto_Rico', 12],
    St_Lucia: ['America/Puerto_Rico', 12],
    St_Thomas: ['America/Puerto_Rico', 12],
    St_Vincent: ['America/Puerto_Rico', 12],
    Swift_Current: ['', 16],
    Tegucigalpa: ['', 16],
    Thule: ['', 35],
    Thunder_Bay: ['America/Toronto', 22],
    Tijuana: ['', 23],
    Toronto: ['', 22],
    Tortola: ['America/Puerto_Rico', 12],
    Vancouver: ['', 23],
    Virgin: ['America/Puerto_Rico', 12],
    Whitehorse: ['', 21],
    Winnipeg: ['', 20],
    Yakutat: ['', 11],
    Yellowknife: ['America/Edmonton', 19],
  },
  Antarctica: {
    Casey: ['', 36],
    Davis: ['', 37],
    DumontDUrville: ['Pacific/Port_Moresby', 38],
    Macquarie: ['', 39],
    Mawson: ['', 40],
    McMurdo: ['Pacific/Auckland', 41],
    Palmer: ['', 13],
    Rothera: ['', 42],
    South_Pole: ['Pacific/Auckland', 41],
    Syowa: ['Asia/Riyadh', 43],
    Troll: ['', 44],
    Vostok: ['Asia/Urumqi', 45],
  },
  Arctic: { Longyearbyen: ['Europe/Berlin', 46] },
  Asia: {
    Aden: ['Asia/Riyadh', 43],
    Almaty: ['', 45],
    Amman: ['', 43],
    Anadyr: ['', 47],
    Aqtau: ['', 40],
    Aqtobe: ['', 40],
    Ashgabat: ['', 40],
    Ashkhabad: ['Asia/Ashgabat', 40],
    Atyrau: ['', 40],
    Baghdad: ['', 43],
    Bahrain: ['Asia/Qatar', 43],
    Baku: ['', 48],
    Bangkok: ['', 37],
    Barnaul: ['', 49],
    Beirut: ['', 50],
    Bishkek: ['', 45],
    Brunei: ['Asia/Kuching', 51],
    Calcutta: ['Asia/Kolkata', 52],
    Chita: ['', 53],
    Choibalsan: ['', 51],
    Chongqing: ['Asia/Shanghai', 54],
    Chungking: ['Asia/Shanghai', 54],
    Colombo: ['', 55],
    Dacca: ['Asia/Dhaka', 45],
    Damascus: ['', 43],
    Dhaka: ['', 45],
    Dili: ['', 56],
    Dubai: ['', 48],
    Dushanbe: ['', 40],
    Famagusta: ['', 57],
    Gaza: ['', 58],
    Harbin: ['Asia/Shanghai', 54],
    Hebron: ['', 58],
    Ho_Chi_Minh: ['', 37],
    Hong_Kong: ['', 59],
    Hovd: ['', 37],
    Irkutsk: ['', 60],
    Istanbul: ['Europe/Istanbul', 61],
    Jakarta: ['', 62],
    Jayapura: ['', 63],
    Jerusalem: ['', 64],
    Kabul: ['', 65],
    Kamchatka: ['', 47],
    Karachi: ['', 66],
    Kashgar: ['Asia/Urumqi', 45],
    Kathmandu: ['', 67],
    Katmandu: ['Asia/Kathmandu', 67],
    Khandyga: ['', 53],
    Kolkata: ['', 52],
    Krasnoyarsk: ['', 49],
    Kuala_Lumpur: ['Asia/Singapore', 51],
    Kuching: ['', 51],
    Kuwait: ['Asia/Riyadh', 43],
    Macao: ['Asia/Macau', 54],
    Macau: ['', 54],
    Magadan: ['', 68],
    Makassar: ['', 69],
    Manila: ['', 70],
    Muscat: ['Asia/Dubai', 48],
    Nicosia: ['', 71],
    Novokuznetsk: ['', 49],
    Novosibirsk: ['', 49],
    Omsk: ['', 72],
    Oral: ['', 40],
    Phnom_Penh: ['Asia/Bangkok', 37],
    Pontianak: ['', 62],
    Pyongyang: ['', 73],
    Qatar: ['', 43],
    Qostanay: ['', 45],
    Qyzylorda: ['', 40],
    Rangoon: ['Asia/Yangon', 74],
    Riyadh: ['', 43],
    Saigon: ['Asia/Ho_Chi_Minh', 37],
    Sakhalin: ['', 68],
    Samarkand: ['', 40],
    Seoul: ['', 73],
    Shanghai: ['', 54],
    Singapore: ['', 51],
    Srednekolymsk: ['', 68],
    Taipei: ['', 54],
    Tashkent: ['', 40],
    Tbilisi: ['', 48],
    Tehran: ['', 75],
    Tel_Aviv: ['Asia/Jerusalem', 64],
    Thimbu: ['Asia/Thimphu', 45],
    Thimphu: ['', 45],
    Tokyo: ['', 76],
    Tomsk: ['', 49],
    Ujung_Pandang: ['Asia/Makassar', 69],
    Ulaanbaatar: ['', 51],
    Ulan_Bator: ['Asia/Ulaanbaatar', 51],
    Urumqi: ['', 45],
    'Ust-Nera': ['', 77],
    Vientiane: ['Asia/Bangkok', 37],
    Vladivostok: ['', 77],
    Yakutsk: ['', 53],
    Yangon: ['', 74],
    Yekaterinburg: ['', 78],
    Yerevan: ['', 48],
  },
  Atlantic: {
    Azores: ['', 79],
    Bermuda: ['', 24],
    Canary: ['', 80],
    Cape_Verde: ['', 81],
    Faeroe: ['Atlantic/Faroe', 82],
    Faroe: ['', 82],
    Jan_Mayen: ['Europe/Berlin', 46],
    Madeira: ['', 80],
    Reykjavik: ['Africa/Abidjan', 0],
    South_Georgia: ['', 30],
    St_Helena: ['Africa/Abidjan', 0],
    Stanley: ['', 13],
  },
  Australia: {
    ACT: ['Australia/Sydney', 39],
    Adelaide: ['', 83],
    Brisbane: ['', 84],
    Broken_Hill: ['', 83],
    Canberra: ['Australia/Sydney', 39],
    Currie: ['Australia/Hobart', 85],
    Darwin: ['', 86],
    Eucla: ['', 87],
    Hobart: ['', 85],
    LHI: ['Australia/Lord_Howe', 88],
    Lindeman: ['', 84],
    Lord_Howe: ['', 88],
    Melbourne: ['', 39],
    North: ['Australia/Darwin', 86],
    NSW: ['Australia/Sydney', 39],
    Perth: ['', 89],
    Queensland: ['Australia/Brisbane', 84],
    South: ['Australia/Adelaide', 83],
    Sydney: ['', 39],
    Tasmania: ['Australia/Hobart', 85],
    Victoria: ['Australia/Melbourne', 39],
    West: ['Australia/Perth', 89],
    Yancowinna: ['Australia/Broken_Hill', 83],
  },
  Brazil: {
    Acre: ['America/Rio_Branco', 18],
    DeNoronha: ['America/Noronha', 30],
    East: ['America/Sao_Paulo', 13],
    West: ['America/Manaus', 17],
  },
  Canada: {
    Atlantic: ['America/Halifax', 24],
    Central: ['America/Winnipeg', 20],
    Eastern: ['America/Toronto', 22],
    Mountain: ['America/Edmonton', 19],
    Newfoundland: ['America/St_Johns', 34],
    Pacific: ['America/Vancouver', 23],
    Saskatchewan: ['America/Regina', 16],
    Yukon: ['America/Whitehorse', 21],
  },
  CET: ['', 46],
  Chile: { Continental: ['America/Santiago', 32], EasterIsland: ['Pacific/Easter', 90] },
  CST6CDT: ['', 20],
  Cuba: ['America/Havana', 28],
  EET: ['', 57],
  Egypt: ['Africa/Cairo', 5],
  Eire: ['Europe/Dublin', 91],
  EST: ['', 15],
  EST5EDT: ['', 22],
  Etc: {
    'GMT-0': ['Etc/GMT', 92],
    'GMT-1': ['', 93],
    'GMT-10': ['', 77],
    'GMT-11': ['', 68],
    'GMT-12': ['', 47],
    'GMT-13': ['', 94],
    'GMT-14': ['', 95],
    'GMT-2': ['', 96],
    'GMT-3': ['', 61],
    'GMT-4': ['', 97],
    'GMT-5': ['', 78],
    'GMT-6': ['', 72],
    'GMT-7': ['', 49],
    'GMT-8': ['', 60],
    'GMT-9': ['', 53],
    GMT: ['', 92],
    'GMT+0': ['Etc/GMT', 92],
    'GMT+1': ['', 98],
    'GMT+10': ['', 99],
    'GMT+11': ['', 100],
    'GMT+12': ['', 101],
    'GMT+2': ['', 30],
    'GMT+3': ['', 13],
    'GMT+4': ['', 17],
    'GMT+5': ['', 18],
    'GMT+6': ['', 102],
    'GMT+7': ['', 103],
    'GMT+8': ['', 104],
    'GMT+9': ['', 105],
    GMT0: ['Etc/GMT', 92],
    Greenwich: ['Etc/GMT', 92],
    UCT: ['Etc/UTC', 106],
    Universal: ['Etc/UTC', 106],
    UTC: ['', 106],
    Zulu: ['Etc/UTC', 106],
  },
  Europe: {
    Amsterdam: ['Europe/Brussels', 46],
    Andorra: ['', 46],
    Astrakhan: ['', 97],
    Athens: ['', 57],
    Belfast: ['Europe/London', 107],
    Belgrade: ['', 7],
    Berlin: ['', 46],
    Bratislava: ['Europe/Prague', 46],
    Brussels: ['', 46],
    Bucharest: ['', 108],
    Budapest: ['', 46],
    Busingen: ['Europe/Zurich', 7],
    Chisinau: ['', 109],
    Copenhagen: ['Europe/Berlin', 46],
    Dublin: ['', 91],
    Gibraltar: ['', 46],
    Guernsey: ['Europe/London', 107],
    Helsinki: ['', 57],
    Isle_of_Man: ['Europe/London', 107],
    Istanbul: ['', 61],
    Jersey: ['Europe/London', 107],
    Kaliningrad: ['', 110],
    Kiev: ['Europe/Kyiv', 111],
    Kirov: ['', 112],
    Kyiv: ['', 111],
    Lisbon: ['', 113],
    Ljubljana: ['Europe/Belgrade', 7],
    London: ['', 107],
    Luxembourg: ['Europe/Brussels', 46],
    Madrid: ['', 7],
    Malta: ['', 46],
    Mariehamn: ['Europe/Helsinki', 57],
    Minsk: ['', 43],
    Monaco: ['Europe/Paris', 46],
    Moscow: ['', 112],
    Nicosia: ['Asia/Nicosia', 71],
    Oslo: ['Europe/Berlin', 46],
    Paris: ['', 46],
    Podgorica: ['Europe/Belgrade', 7],
    Prague: ['', 46],
    Riga: ['', 57],
    Rome: ['', 46],
    Samara: ['', 97],
    San_Marino: ['Europe/Rome', 46],
    Sarajevo: ['Europe/Belgrade', 7],
    Saratov: ['', 97],
    Simferopol: ['', 112],
    Skopje: ['Europe/Belgrade', 7],
    Sofia: ['', 57],
    Stockholm: ['Europe/Berlin', 46],
    Tallinn: ['', 57],
    Tirane: ['', 46],
    Tiraspol: ['Europe/Chisinau', 109],
    Ulyanovsk: ['', 97],
    Uzhgorod: ['Europe/Kyiv', 111],
    Vaduz: ['Europe/Zurich', 7],
    Vatican: ['Europe/Rome', 46],
    Vienna: ['', 46],
    Vilnius: ['', 57],
    Volgograd: ['', 112],
    Warsaw: ['', 46],
    Zagreb: ['Europe/Belgrade', 7],
    Zaporozhye: ['Europe/Kyiv', 111],
    Zurich: ['', 7],
  },
  'GB-Eire': ['Europe/London', 107],
  GB: ['Europe/London', 107],
  'GMT-0': ['Etc/GMT', 92],
  GMT: ['Etc/GMT', 92],
  'GMT+0': ['Etc/GMT', 92],
  GMT0: ['Etc/GMT', 92],
  Greenwich: ['Etc/GMT', 92],
  Hongkong: ['Asia/Hong_Kong', 59],
  HST: ['', 114],
  Iceland: ['Africa/Abidjan', 0],
  Indian: {
    Antananarivo: ['Africa/Nairobi', 1],
    Chagos: ['', 45],
    Christmas: ['Asia/Bangkok', 37],
    Cocos: ['Asia/Yangon', 74],
    Comoro: ['Africa/Nairobi', 1],
    Kerguelen: ['Indian/Maldives', 40],
    Mahe: ['Asia/Dubai', 48],
    Maldives: ['', 40],
    Mauritius: ['', 48],
    Mayotte: ['Africa/Nairobi', 1],
    Reunion: ['Asia/Dubai', 48],
  },
  Iran: ['Asia/Tehran', 75],
  Israel: ['Asia/Jerusalem', 64],
  Jamaica: ['America/Jamaica', 15],
  Japan: ['Asia/Tokyo', 76],
  Kwajalein: ['Pacific/Kwajalein', 115],
  Libya: ['Africa/Tripoli', 9],
  MET: ['', 116],
  Mexico: {
    BajaNorte: ['America/Tijuana', 23],
    BajaSur: ['America/Mazatlan', 21],
    General: ['America/Mexico_City', 16],
  },
  MST: ['', 21],
  MST7MDT: ['', 19],
  Navajo: ['America/Denver', 19],
  'NZ-CHAT': ['Pacific/Chatham', 117],
  NZ: ['Pacific/Auckland', 41],
  Pacific: {
    Apia: ['', 118],
    Auckland: ['', 41],
    Bougainville: ['', 36],
    Chatham: ['', 117],
    Chuuk: ['Pacific/Port_Moresby', 38],
    Easter: ['', 90],
    Efate: ['', 36],
    Enderbury: ['Pacific/Kanton', 118],
    Fakaofo: ['', 118],
    Fiji: ['', 115],
    Funafuti: ['Pacific/Tarawa', 115],
    Galapagos: ['', 102],
    Gambier: ['', 119],
    Guadalcanal: ['', 36],
    Guam: ['', 120],
    Honolulu: ['', 114],
    Johnston: ['Pacific/Honolulu', 114],
    Kanton: ['', 118],
    Kiritimati: ['', 121],
    Kosrae: ['', 36],
    Kwajalein: ['', 115],
    Majuro: ['Pacific/Tarawa', 115],
    Marquesas: ['', 122],
    Midway: ['Pacific/Pago_Pago', 123],
    Nauru: ['', 115],
    Niue: ['', 124],
    Norfolk: ['', 125],
    Noumea: ['', 36],
    Pago_Pago: ['', 123],
    Palau: ['', 56],
    Pitcairn: ['', 126],
    Pohnpei: ['Pacific/Guadalcanal', 36],
    Ponape: ['Pacific/Guadalcanal', 36],
    Port_Moresby: ['', 38],
    Rarotonga: ['', 127],
    Saipan: ['Pacific/Guam', 120],
    Samoa: ['Pacific/Pago_Pago', 123],
    Tahiti: ['', 127],
    Tarawa: ['', 115],
    Tongatapu: ['', 118],
    Truk: ['Pacific/Port_Moresby', 38],
    Wake: ['Pacific/Tarawa', 115],
    Wallis: ['Pacific/Tarawa', 115],
    Yap: ['Pacific/Port_Moresby', 38],
  },
  Poland: ['Europe/Warsaw', 46],
  Portugal: ['Europe/Lisbon', 113],
  PRC: ['Asia/Shanghai', 54],
  PST8PDT: ['', 23],
  ROC: ['Asia/Taipei', 54],
  ROK: ['Asia/Seoul', 73],
  Singapore: ['Asia/Singapore', 51],
  Turkey: ['Europe/Istanbul', 61],
  UCT: ['Etc/UTC', 106],
  Universal: ['Etc/UTC', 106],
  US: {
    Alaska: ['America/Anchorage', 11],
    Aleutian: ['America/Adak', 10],
    Arizona: ['America/Phoenix', 21],
    Central: ['America/Chicago', 20],
    'East-Indiana': ['America/Indiana/Indianapolis', 22],
    Eastern: ['America/New_York', 22],
    Hawaii: ['Pacific/Honolulu', 114],
    'Indiana-Starke': ['America/Indiana/Knox', 20],
    Michigan: ['America/Detroit', 22],
    Mountain: ['America/Denver', 19],
    Pacific: ['America/Los_Angeles', 23],
    Samoa: ['Pacific/Pago_Pago', 123],
  },
  UTC: ['Etc/UTC', 106],
  'W-SU': ['Europe/Moscow', 112],
  WET: ['', 82],
  Zulu: ['Etc/UTC', 106],
};
const tzlibZonesDetailsDB = [
  '20230517T170335Z<n><bs><n><tz>GMT<n><of>+0000<n><ot>+0000<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>EAT<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>CET<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>WAT<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>CAT<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700424T000000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=-1FR<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701030T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1FR<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+01<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>CEST<n><of>+0100<n><ot>+0200<n><s>19700329T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>CET<n><of>+0200<n><ot>+0100<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>SAST<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>EET<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>HDT<n><of>-1000<n><ot>-0900<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>HST<n><of>-0900<n><ot>-1000<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>AKDT<n><of>-0900<n><ot>-0800<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>AKST<n><of>-0800<n><ot>-0900<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>AST<n><of>-0400<n><ot>-0400<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-03<n><of>-0300<n><ot>-0300<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>-03<n><of>-0400<n><ot>-0300<n><s>19701004T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n><bs><n><tz>-04<n><of>-0300<n><ot>-0400<n><s>19700322T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=4SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>EST<n><of>-0500<n><ot>-0500<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>CST<n><of>-0600<n><ot>-0600<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-04<n><of>-0400<n><ot>-0400<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-05<n><of>-0500<n><ot>-0500<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>MDT<n><of>-0700<n><ot>-0600<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>MST<n><of>-0600<n><ot>-0700<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>CDT<n><of>-0600<n><ot>-0500<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>CST<n><of>-0500<n><ot>-0600<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>MST<n><of>-0700<n><ot>-0700<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>EDT<n><of>-0500<n><ot>-0400<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>EST<n><of>-0400<n><ot>-0500<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>PDT<n><of>-0800<n><ot>-0700<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>PST<n><of>-0700<n><ot>-0800<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>ADT<n><of>-0400<n><ot>-0300<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>AST<n><of>-0300<n><ot>-0400<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>-01<n><of>-0200<n><ot>-0100<n><s>19700328T230000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SA<n><ed><n><bs><n><tz>-02<n><of>-0100<n><ot>-0200<n><s>19701025T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>AST<n><of>-0300<n><ot>-0400<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>ADT<n><of>-0400<n><ot>-0300<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20230517T170336Z<n><bs><n><tz>EST<n><of>-0400<n><ot>-0500<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>EDT<n><of>-0500<n><ot>-0400<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20230517T170336Z<n><bs><n><tz>CST<n><of>-0400<n><ot>-0500<n><s>19701101T010000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>CDT<n><of>-0500<n><ot>-0400<n><s>19700308T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20230517T170336Z<n><bd><n><tz>-02<n><of>-0300<n><ot>-0200<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>-03<n><of>-0200<n><ot>-0300<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-02<n><of>-0200<n><ot>-0200<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>CST<n><of>-0500<n><ot>-0600<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>CDT<n><of>-0600<n><ot>-0500<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20230517T170336Z<n><bs><n><tz>-04<n><of>-0300<n><ot>-0400<n><s>19700405T000000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>-03<n><of>-0400<n><ot>-0300<n><s>19700906T000000<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=1SU<n><ed><n>',
  '20230517T170335Z<n><bd><n><tz>+00<n><of>-0100<n><ot>+0000<n><s>19700329T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>-01<n><of>+0000<n><ot>-0100<n><s>19701025T010000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>NST<n><of>-0230<n><ot>-0330<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n><bd><n><tz>NDT<n><of>-0330<n><ot>-0230<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n>',
  '20230517T170335Z<n><bd><n><tz>ADT<n><of>-0400<n><ot>-0300<n><s>19700308T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=2SU<n><ed><n><bs><n><tz>AST<n><of>-0300<n><ot>-0400<n><s>19701101T020000<n><r>FREQ=YEARLY;BYMONTH=11;BYDAY=1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+11<n><of>+1100<n><ot>+1100<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+07<n><of>+0700<n><ot>+0700<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+10<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>AEST<n><of>+1100<n><ot>+1000<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>AEDT<n><of>+1000<n><ot>+1100<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n>',
  '20230517T170335Z<n><bs><n><tz>+05<n><of>+0500<n><ot>+0500<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>NZDT<n><of>+1200<n><ot>+1300<n><s>19700927T020000<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=-1SU<n><ed><n><bs><n><tz>NZST<n><of>+1300<n><ot>+1200<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>-03<n><of>-0300<n><ot>-0300<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+03<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>+02<n><of>+0000<n><ot>+0200<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>+00<n><of>+0200<n><ot>+0000<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+06<n><of>+0600<n><ot>+0600<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>CEST<n><of>+0100<n><ot>+0200<n><s>19700329T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>CET<n><of>+0200<n><ot>+0100<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+12<n><of>+1200<n><ot>+1200<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+04<n><of>+0400<n><ot>+0400<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+07<n><of>+0700<n><ot>+0700<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T000000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+08<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>IST<n><of>+0530<n><ot>+0530<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+09<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>CST<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+0530<n><of>+0530<n><ot>+0530<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+09<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T030000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T040000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701024T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SA<n><es><n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700328T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SA<n><ed><n>',
  '20230517T170335Z<n><bs><n><tz>HKT<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+08<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+03<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>WIB<n><of>+0700<n><ot>+0700<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>WIT<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>IDT<n><of>+0200<n><ot>+0300<n><s>19700327T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1FR<n><ed><n><bs><n><tz>IST<n><of>+0300<n><ot>+0200<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+0430<n><of>+0430<n><ot>+0430<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>PKT<n><of>+0500<n><ot>+0500<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+0545<n><of>+0545<n><ot>+0545<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+11<n><of>+1100<n><ot>+1100<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>WITA<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>PST<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T040000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T030000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n>',
  '20230517T170336Z<n><bs><n><tz>+06<n><of>+0600<n><ot>+0600<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>KST<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+0630<n><of>+0630<n><ot>+0630<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+0330<n><of>+0330<n><ot>+0330<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>JST<n><of>+0900<n><ot>+0900<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+10<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+05<n><of>+0500<n><ot>+0500<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>+00<n><of>-0100<n><ot>+0000<n><s>19700329T000000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>-01<n><of>+0000<n><ot>-0100<n><s>19701025T010000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>WEST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>WET<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>-01<n><of>-0100<n><ot>-0100<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>WEST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>WET<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>ACST<n><of>+1030<n><ot>+0930<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>ACDT<n><of>+0930<n><ot>+1030<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n>',
  '20230517T170335Z<n><bs><n><tz>AEST<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>AEDT<n><of>+1000<n><ot>+1100<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n><bs><n><tz>AEST<n><of>+1100<n><ot>+1000<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>ACST<n><of>+0930<n><ot>+0930<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+0845<n><of>+0845<n><ot>+0845<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+1030<n><of>+1100<n><ot>+1030<n><s>19700405T020000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n><bd><n><tz>+11<n><of>+1030<n><ot>+1100<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n>',
  '20230517T170335Z<n><bs><n><tz>AWST<n><of>+0800<n><ot>+0800<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-06<n><of>-0500<n><ot>-0600<n><s>19700404T220000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SA<n><es><n><bd><n><tz>-05<n><of>-0600<n><ot>-0500<n><s>19700905T220000<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=1SA<n><ed><n>',
  '20230517T170335Z<n><bs><n><tz>IST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><es><n><bd><n><tz>GMT<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><ed><n>',
  '20230517T170336Z<n><bs><n><tz>GMT<n><of>+0000<n><ot>+0000<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+01<n><of>+0100<n><ot>+0100<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+13<n><of>+1300<n><ot>+1300<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+14<n><of>+1400<n><ot>+1400<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+02<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>+04<n><of>+0400<n><ot>+0400<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-01<n><of>-0100<n><ot>-0100<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-10<n><of>-1000<n><ot>-1000<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-11<n><of>-1100<n><ot>-1100<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-12<n><of>-1200<n><ot>-1200<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-06<n><of>-0600<n><ot>-0600<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-07<n><of>-0700<n><ot>-0700<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-08<n><of>-0800<n><ot>-0800<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>-09<n><of>-0900<n><ot>-0900<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>UTC<n><of>+0000<n><ot>+0000<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>BST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>GMT<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170336Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T030000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T040000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>EET<n><of>+0200<n><ot>+0200<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>EET<n><of>+0300<n><ot>+0200<n><s>19701025T040000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n><bd><n><tz>EEST<n><of>+0200<n><ot>+0300<n><s>19700329T030000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n>',
  '20230517T170336Z<n><bs><n><tz>MSK<n><of>+0300<n><ot>+0300<n><s>19700101T000000<n><es><n>',
  '20230517T170336Z<n><bs><n><tz>WET<n><of>+0100<n><ot>+0000<n><s>19701025T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n><bd><n><tz>WEST<n><of>+0000<n><ot>+0100<n><s>19700329T010000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n>',
  '20230517T170336Z<n><bs><n><tz>HST<n><of>-1000<n><ot>-1000<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+12<n><of>+1200<n><ot>+1200<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>MEST<n><of>+0100<n><ot>+0200<n><s>19700329T020000<n><r>FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU<n><ed><n><bs><n><tz>MET<n><of>+0200<n><ot>+0100<n><s>19701025T030000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>+1345<n><of>+1245<n><ot>+1345<n><s>19700927T024500<n><r>FREQ=YEARLY;BYMONTH=9;BYDAY=-1SU<n><ed><n><bs><n><tz>+1245<n><of>+1345<n><ot>+1245<n><s>19700405T034500<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+13<n><of>+1300<n><ot>+1300<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>-09<n><of>-0900<n><ot>-0900<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>ChST<n><of>+1000<n><ot>+1000<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>+14<n><of>+1400<n><ot>+1400<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>-0930<n><of>-0930<n><ot>-0930<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>SST<n><of>-1100<n><ot>-1100<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>-11<n><of>-1100<n><ot>-1100<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bd><n><tz>+12<n><of>+1100<n><ot>+1200<n><s>19701004T020000<n><r>FREQ=YEARLY;BYMONTH=10;BYDAY=1SU<n><ed><n><bs><n><tz>+11<n><of>+1200<n><ot>+1100<n><s>19700405T030000<n><r>FREQ=YEARLY;BYMONTH=4;BYDAY=1SU<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>-08<n><of>-0800<n><ot>-0800<n><s>19700101T000000<n><es><n>',
  '20230517T170335Z<n><bs><n><tz>-10<n><of>-1000<n><ot>-1000<n><s>19700101T000000<n><es><n>',
];
function tzlib_get_content(tzName) {
  const nameParts = tzName.split('/');
  if (
    (nameParts.length === 3 &&
      (!tzlibZonesDB[`${nameParts[0]}`] ||
        !tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`] ||
        !tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][`${nameParts[2]}`])) ||
    (nameParts.length === 2 &&
      (!tzlibZonesDB[`${nameParts[0]}`] || !tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`])) ||
    (nameParts.length === 1 && !tzlibZonesDB[`${nameParts[0]}`])
  ) {
    console.error('Given timezone not valid.');
    return '';
  }
  if (nameParts.length === 3) {
    return [
      tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][`${nameParts[2]}`][0],
      tzlib_enrich_data(
        tzlibZonesDetailsDB[tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][`${nameParts[2]}`][1]]
      ),
    ];
  }
  if (nameParts.length === 2) {
    return [
      tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][0],
      tzlib_enrich_data(tzlibZonesDetailsDB[tzlibZonesDB[`${nameParts[0]}`][`${nameParts[1]}`][1]]),
    ];
  }
  return [
    tzlibZonesDB[`${nameParts[0]}`][0],
    tzlib_enrich_data(tzlibZonesDetailsDB[tzlibZonesDB[`${nameParts[0]}`][1]]),
  ];
}
function tzlib_enrich_data(string) {
  const shortenerMap = {
    '<br>': '<n>',
    'TZNAME:': '<tz>',
    'TZOFFSETFROM:': '<of>',
    'TZOFFSETTO:': '<ot>',
    'DTSTART:': '<s>',
    'RRULE:': '<r>',
    'BEGIN:DAYLIGHT': '<bd>',
    'END:DAYLIGHT': '<ed>',
    'BEGIN:STANDARD': '<bs>',
    'END:STANDARD': '<es>',
  };
  for (const [key, value] of Object.entries(shortenerMap)) {
    string = string.replaceAll(value, key);
  }
  return string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function tzlib_get_ical_block(tzName, jsonType = false) {
  const tzBlock = tzlib_get_content(tzName);
  if (tzBlock[1] == null || tzBlock[1] == '') {
    return '';
  }
  const location = (function () {
    if (tzBlock[0] == '') {
      return tzName;
    } else {
      return tzBlock[0];
    }
  })();
  const tzidLine = 'TZID=' + location;
  const output = [
    'BEGIN:VTIMEZONE\r\nTZID:' +
      location +
      '\r\nX-LIC-LOCATION:' +
      location +
      '\r\nLAST-MODIFIED:' +
      tzBlock[1].replace(/[^\w_\-:,;=+/<br>]/g, '').replace(/<br>/g, '\r\n') +
      'END:VTIMEZONE',
    tzidLine,
  ];
  if (jsonType) {
    return JSON.stringify(output);
  }
  return output;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function tzlib_get_offset(tzName, isoDate, isoTime) {
  const tzBlock = tzlib_get_content(tzName);
  if (tzBlock[1] == null || tzBlock[1] == '') {
    return '';
  }
  if (!isoDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
    console.error('offset calculation failed: date misspelled [-> YYYY-MM-DD]');
    return '';
  }
  if (!isoTime.match(/^\d{2}:\d{2}$/)) {
    console.error('offset calculation failed: time misspelled [-> hh:mm]');
    return '';
  }
  if (!tzBlock[1].match(/BEGIN:DAYLIGHT/i)) {
    return tzBlock[1].match(/TZOFFSETTO:([+|-]\d{4})/i)[1];
  }
  const dateString = isoDate + 'T' + isoTime + ':00';
  const date = new Date(dateString);
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1;
  const dateDay = date.getDate();
  const dateHour = date.getHours();
  const timezoneData = tzBlock[1].replace(/[^\w_\-:,;=+/<br>]/g, '').split('<br>');
  const tzBreakpoints = { 1: {}, 2: {} };
  let breakpointCount = 0;
  for (let i = 0; i < timezoneData.length; i++) {
    if (timezoneData[`${i}`].startsWith('TZOFFSETTO')) {
      breakpointCount++;
      tzBreakpoints[`${breakpointCount}`].offset = timezoneData[`${i}`].split(':')[1];
    }
    if (timezoneData[`${i}`].startsWith('DTSTART')) {
      tzBreakpoints[`${breakpointCount}`].hour = parseInt(timezoneData[`${i}`].substr(17, 2));
    }
    if (timezoneData[`${i}`].startsWith('RRULE')) {
      let rruleParts = timezoneData[`${i}`].split(';');
      let rruleMonth = parseInt(rruleParts[1].split('=')[1]);
      tzBreakpoints[`${breakpointCount}`].month = parseInt(rruleMonth);
      tzBreakpoints[`${breakpointCount}`].day = rruleParts[2].split('=')[1];
    }
  }
  if (tzBreakpoints[1].month > tzBreakpoints[2].month) {
    [tzBreakpoints[1], tzBreakpoints[2]] = [tzBreakpoints[2], tzBreakpoints[1]];
  }
  if (dateMonth != tzBreakpoints[1].month && dateMonth != tzBreakpoints[2].month) {
    if (dateMonth < tzBreakpoints[1].month || dateMonth > tzBreakpoints[2].month) {
      return tzBreakpoints[2].offset;
    } else {
      return tzBreakpoints[1].offset;
    }
  }
  const theCase = (function () {
    return Object.keys(tzBreakpoints).find((key) => tzBreakpoints[`${key}`].month == dateMonth);
  })();
  const helperArrayWeekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  const numberDays = new Date(dateYear, dateMonth, 0).getDate();
  let weekdayCount = new Date(dateYear, dateMonth - 1, 1).getDay();
  const weekdays = { SU: {}, MO: {}, TU: {}, WE: {}, TH: {}, FR: {}, SA: {} };
  for (let d = 1; d <= numberDays; d++) {
    const occurence = Object.keys(weekdays[helperArrayWeekdays[`${weekdayCount}`]]).length + 1;
    weekdays[helperArrayWeekdays[`${weekdayCount}`]][`${occurence}`] = d;
    weekdayCount++;
    if (weekdayCount == 7) {
      weekdayCount = 0;
    }
  }
  const actualDay = (function () {
    if (tzBreakpoints[`${theCase}`].day[0] == '-') {
      const breakpointWeekday = tzBreakpoints[`${theCase}`].day.substr(2, 2);
      const dayIndex =
        Object.keys(weekdays[`${breakpointWeekday}`]).length +
        1 -
        parseInt(tzBreakpoints[`${theCase}`].day[1]);
      return weekdays[`${breakpointWeekday}`][`${dayIndex}`];
    } else {
      const breakpointWeekday = tzBreakpoints[`${theCase}`].day.substr(1, 2);
      return weekdays[`${breakpointWeekday}`][tzBreakpoints[`${theCase}`].day[0]];
    }
  })();
  if (dateDay > actualDay || (dateDay == actualDay && dateHour >= tzBreakpoints[`${theCase}`].hour)) {
    return tzBreakpoints[`${theCase}`].offset;
  }
  const fallbackCase = (function () {
    if (theCase == 1) {
      return 2;
    } else {
      return 1;
    }
  })();
  return tzBreakpoints[`${fallbackCase}`].offset;
}
let tzlibZoneNames = [];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function tzlib_get_timezones(jsonType = false) {
  if (tzlibZoneNames.length == 0) {
    tzlibZoneNames = (function () {
      let namesArr = [];
      for (const [key, value] of Object.entries(tzlibZonesDB)) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          for (const [key2, value2] of Object.entries(value)) {
            if (typeof value2 === 'object' && !Array.isArray(value2)) {
              for (const [key3] of Object.entries(value2)) {
                namesArr.push(key + '/' + key2 + '/' + key3);
              }
            } else {
              namesArr.push(key + '/' + key2);
            }
          }
        } else {
          namesArr.push(key);
        }
      }
      return namesArr;
    })();
  }
  if (jsonType) {
    return JSON.stringify(tzlibZoneNames);
  }
  return tzlibZoneNames;
}
export { tzlib_get_ical_block, tzlib_get_offset, tzlib_get_timezones };
