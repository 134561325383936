import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-survey-box',
  templateUrl: './survey-box.component.html',
  styleUrls: ['./survey-box.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SurveyBoxComponent implements OnInit, OnChanges {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<boolean> = new EventEmitter();

  @Input() showCloseButton: boolean = true;
  @Input() showBackButton: boolean = false;

  @Input() style: SurveyBoxStyle;

  show: SurveyBoxShow = {
    buttons: {
      close: true,
      back: false,
    }
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setShow();
  }

  ngOnInit(): void {
    this.setShow();
  }

  closeSurvey(data: any = false) {
    this.close.emit(data)
  }

  backSurvey() {
    this.back.emit(true)
  }

  setShow() {
    this.show.buttons.close = this.showCloseButton;
    this.show.buttons.back = this.showBackButton;
  }
}

export interface SurveyBoxShow {
  buttons?: {
    close: boolean,
    back: boolean,
  }
}

export interface SurveyBoxStyle {
  box?: {
    position?: 'absolute' | 'fixed' | 'relative' | 'unset',
    top?: string,
    right?: string,
    bottom?: string,
    left?: string,
    'flex-direction'?: string
    'align-items'?: string
    'min-height'?: string
  }
  body?: {
    'background-color'?: string
    color?: string
  },
} 