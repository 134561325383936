<div class="wrapper">
    <div class="loginCard" align="center">
      <mat-card style="padding: 30px;">
        <form [formGroup]="fg" *ngIf="step == 1 || step == 2">
          <div class="row">
            <div class="col-12">
                <h3>Faça o login para continuar</h3>
            </div>
    
            <div class="col-12" *ngIf="step === 1">
              <mat-form-field appearance="outline">
                <mat-label style="font-size: 17px;">CPF</mat-label>
                <input
                  matInput
                  type="tel"
                  formControlName="id"
                  [specialCharacters]="['/', '.', '-']"
                  [mask]="'000.000.000-00'"
                  required
                />
              </mat-form-field>
            </div>

            <div class="col-12" *ngIf="step === 2">
                <mat-form-field appearance="outline">
                <mat-label style="font-size: 17px;">Senha</mat-label>
                <input
                    matInput
                    type="password"
                    formControlName="password"
                    required
                />
                </mat-form-field>
            </div>
    
            <div class="col-12" align="left" *ngIf="step === 2">
                <a *ngIf="!isOnIframe"
                (click)="forgotPassword()"
                style="font-weight: bold; text-decoration: none; font-size: 16px; cursor: pointer;"
                [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'"
                ><u>Esqueci minha senha</u></a
                >
            </div>

            <div class="col-12 p-margin-top-3">
              <button 
                *ngIf="!isOnIframe" 
                mat-button 
                [ngClass]="isAmorSaude? 'btn-amorSaudeTertiary btn-large' : 'btn-secondary btn-large'" 
                (click)="checkRegistration()"
              >
                <span *ngIf="!loging; else loadingLogin">
                  {{ step === 1?'Continuar' : 'Entrar'}}
                </span>
    
                <ng-template #loadingLogin>
                  <mat-spinner [diameter]="30" color="primary"> </mat-spinner>
                </ng-template>
              </button>
            </div>
            <div class="col-12 p-margin-top-3">
                <button 
                  *ngIf="step === 2" 
                  mat-button 
                  [ngClass]="isAmorSaude? 'btn-amorSaudeTertiary btn-large' : 'btn-secondary btn-large'" 
                  (click)="stepBack()"
                >
                  <span>
                    Voltar
                  </span>
                </button>
            </div>
          </div>
        </form>
        <ng-container *ngIf="step === 3">
          <app-esqueci-minha-senha
            [data]="{cpf: fg.value.id}"
            (onCloseComponent)="forgotPassword($event)"
          >

          </app-esqueci-minha-senha>
        </ng-container>
        <ng-container *ngIf="step === 4">
          <app-cadastro
            [data]="{cpf: fg.value.id}"
            (onCloseComponent)="forgotPassword($event)"
          >
          </app-cadastro>
        </ng-container>
      </mat-card>
    
      <mat-card *ngIf="!isOnIframe && !isSchedulersView && step !== 3 && step !== 4 && isAllowedToRegister" align="center" style="margin-top: 15px;">
        Ainda não é cadastrado?
        <a 
            (click)="newUser()" 
            [ngClass]="isAmorSaude? 'amorsaudeColor' : 'purple'"
            style="white-space: nowrap; cursor: pointer;"
        >
          <b><u>Clique aqui</u></b>
        </a>
      </mat-card>
    </div>
  </div>
  
