// import { ConvenioService } from './services/covenio.service';

import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgendaComponent } from './pages/agendamentos/agendamentos.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { EsqueciMinhaSenhaComponent } from './pages/esqueci-minha-senha/esqueci-minha-senha.component';
import { HistoryComponent } from './pages/history/history.component';
import { LoginComponent } from './pages/login/login.component';
import { NovoagendamentoComponent } from './pages/novoagendamento/novoagendamento.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { AgendamentosService } from './services/agendamentos.service';
import { CadastroService } from './services/cadastro.service';
import { ErrorInterceptor } from './services/Interceptor';
import { CompanyService } from './services/company.service';
import { LoginService } from './services/login.service';
import { PacienteService } from './services/paciente.service';
import { AvatarComponent } from './shared/components/avatar/avatar.component';
import { BreadcrumbsComponent } from './shared/components/breadcrumbs/breadcrumbs.component';
import { CardItemComponent } from './shared/components/card-item/card-item.component';
import { ConfirmationComponent } from './shared/components/dialogs/confirmation/confirmation.component';
import { FormConvenioComponent } from './shared/components/form-convenio/form-convenio.component';
import { ListComponent } from './shared/components/listAgendamentos/list.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { NavigationBarComponent } from './shared/components/navigation-bar/navigation-bar.component';
import { OfflineBarComponent } from './shared/components/offline-bar/offline-bar.component';
import { LocalFontAwesome } from './shared/modules/fontawesome/fontawesome.module';
import { LocalMaterialModule } from './shared/modules/material/material.module';
import {
  ConfirmacaoComponent,
  ControlsComponent,
  HorariosComponent,
  InputsComponent,
  PreConfirmacaoComponent,
} from './shared/partials/agendamento';
import { DatePipe } from './shared/pipes/date.pipe';
import { InitialLettersPipe } from './shared/pipes/initial-letters.pipe';
import { ParseGenderPipe } from './shared/pipes/parse-gender.pipe';
import { AtivarEmailComponent } from './pages/ativar-email/ativar-email.component';
import { RedefinirSenhaComponent } from './pages/redefinir-senha/redefinir-senha.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ConfirmarConsultaComponent } from './pages/confirmar-consulta/confirmar-consulta.component';
import { CardHorarioComponent } from './shared/partials/agendamento/horarios/card-horario/card-horario.component';
import { DetailsComponent } from './shared/partials/agendamento/horarios/details/details.component';
import { ConcatPipe } from './shared/pipes/concat.pipe';
import { ModalPaymentComponent } from './shared/components/modal-payment/modal-payment.component';
import { SafePipe, SafePipeHTML } from './shared/pipes/safe.pipe';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { MeusArquivosComponent } from './pages/meus-arquivos/meus-arquivos.component';
import { PartialPipe } from './shared/pipes/partial.pipe';
import { CampanhasComponent } from './pages/campanhas/campanhas.component';
import { HitoricoDetalhadoComponent } from './pages/hitorico-detalhado/hitorico-detalhado.component';
import { DetalhesCampanhaComponent } from './pages/campanhas/detalhes-campanha/detalhes-campanha.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagarConsultaComponent } from './pages/pagarconsulta/pagarconsulta.component';
import { UsageRulesComponent } from './shared/components/modal-payment/usage-rules/usage-rules.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PaymentInfoComponent } from './shared/components/card-item/payment-info/payment-info.component';
import { CompleteAddressComponent } from './shared/components/modal-payment/complete-address/complete-address.component';
import { ProfileCardComponent } from './shared/components/profile-card/profile-card.component';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { WaitingRoomComponent } from './shared/components/waiting-room/waiting-room.component';
import { PartialLoginComponent } from './shared/components/partial-login/partial-login.component';
import { GeneratePaymentLinkComponent } from './shared/components/generate-payment-link/generate-payment-link.component';
import { SearchPatientComponent } from './shared/components/search-patient/search-patient.component';
import { SurveyNpsComponent } from './shared/components/survey-nps/survey-nps.component';
import { SurveyBoxComponent } from './shared/components/survey-box/survey-box.component';
import { DocumentViewerComponent } from './pages/document-viewer/document-viewer.component';

const config: SocketIoConfig = { url: environment.api_message.replace('/v1', ''), options: {} };

@NgModule({
  declarations: [
    AppComponent,
    AgendaComponent,
    NavigationBarComponent,
    HistoryComponent,
    ListComponent,
    PerfilComponent,
    LoginComponent,
    NovoagendamentoComponent,
    CadastroComponent,
    FormConvenioComponent,
    EsqueciMinhaSenhaComponent,
    ConfirmationComponent,
    BreadcrumbsComponent,
    CardItemComponent,
    InitialLettersPipe,
    LoadingComponent,
    OfflineBarComponent,
    OfflineBarComponent,
    ConfirmacaoComponent,
    PreConfirmacaoComponent,
    HorariosComponent,
    InputsComponent,
    ControlsComponent,
    DatePipe,
    AvatarComponent,
    OfflineBarComponent,
    AtivarEmailComponent,
    RedefinirSenhaComponent,
    FooterComponent,
    ConfirmarConsultaComponent,
    CardHorarioComponent,
    DetailsComponent,
    ParseGenderPipe,
    ConcatPipe,
    ModalPaymentComponent,
    SafePipe,
    SafePipeHTML,
    MeusArquivosComponent,
    PartialPipe,
    CampanhasComponent,
    HitoricoDetalhadoComponent,
    DetalhesCampanhaComponent,
    NotFoundComponent,
    PagarConsultaComponent,
    UsageRulesComponent,
    PaymentInfoComponent,
    CompleteAddressComponent,
    ProfileCardComponent,
    WaitingRoomComponent,
    PartialLoginComponent,
    GeneratePaymentLinkComponent,
    SearchPatientComponent,
    SurveyNpsComponent,
    SurveyBoxComponent,
    DocumentViewerComponent
  ],
  imports: [
    BrowserModule,
    InfiniteScrollModule,
    AppRoutingModule,
    LocalMaterialModule,
    LocalFontAwesome,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    LayoutModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    QRCodeModule
  ],
  providers: [
    CadastroService,
    AgendamentosService,
    PacienteService,
    LoginService,
    CompanyService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
  ],
  entryComponents: [FormConvenioComponent, ConfirmationComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {}
}
