import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CadastroService } from 'src/app/services/cadastro.service';
import { FormConvenioComponent } from 'src/app/shared/components/form-convenio/form-convenio.component';
import { Views } from '../views';
import {
  ICadastroRequest,
  IHealthPlanResponse,
  IGender,
  CheckDocumentResponse,
} from './cadastro.interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountInfo, LocalStorageUtil } from 'src/app/util/local-storage-util';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { IConvenioSelect } from '../perfil/perfil.interface';
import { LoginService } from 'src/app/services/login.service';
import { AmorSaudeService } from 'src/app/services/amor-saude.service';
import { SlackService } from 'src/app/services/slack.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss'],
})
export class CadastroComponent extends Views implements OnInit, OnDestroy {
  @Input() data: any = null;
  @Output() onCloseComponent: EventEmitter<any> = new EventEmitter();
  
  private unsubscribe$: Subject<void> = new Subject();
  public isLoading: boolean;

  public genderArr: IGender[] = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
    { value: 'I', label: 'Indeterminado' }
  ];

  @Output() selectionChange: EventEmitter<MatSelectChange>;
  screen = 'Perfil';
  icon = {
    name: 'user',
    type: 'far',
  };
  page = 'cadastro';
  convenios: IConvenioSelect[] = [];
  convenioSelecionado: IConvenioSelect;
  exibirConvenios = false;
  hidePassword = true;
  isAmorSaude = false;
  healthPlans: IHealthPlanResponse[];
  
  cpfChecked: boolean = false;
  registerNotFound: boolean = false;
  acceptTerms: boolean = false;
  className: string ='';
  waitingRoomHash: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    public cadastroService: CadastroService,
    public loginService: LoginService,
    public amorSaudeService: AmorSaudeService,
    public slackService: SlackService
  ) {
    super();

    this.isAmorSaude = this.loginService.isAmorSaude();
    this.acceptTerms = this.isAmorSaude ? false : true;

    this.waitingRoomHash = this.activeRoute.snapshot.queryParams['wr'] || '';

    if(!this.account)
      this.cadastroService
      .loadAccount(environment.subdomain)
      .subscribe((account: AccountInfo) => {
        this.account = account;
        LocalStorageUtil.setAccountInfo(account);
      });

    this.buildForm();

  }

  public ngOnInit() {
    this.initForm(this.fb);

    if(this.data && this.data?.cpf)
      this.fg.controls.cpf.setValue(this.data.cpf);

    this.fg.controls.celularDDI.setValue('55');
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openModal() {
    const dialogRef = this.dialog.open(FormConvenioComponent, {
      data: {
        convenios: this.healthPlans,
        index: this.fg.controls.convenio.value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.exibirConvenios = true;
      if (result == 'cancel') {
        this.fg.controls.convenio.setValue(null);
      } else {
        this.convenioSelecionado = result.data;
        this.fg.controls.convenio.setValue(this.convenioSelecionado.index);
      }
    });
  }

  public verificarDocumentoOuCadastrar(){
    if(this.cpfChecked)
      this.checkCpf();
    else
      this.cadastra();
  }

  public async checkCpf(){
    if(!this.fg.controls.cpf.valid){
      this.snack.open('Favor informar um CPF para continuar', '', {
        duration: 5000,
        panelClass: this.className
      });
      return;
    }

    this.isLoading = true;
    await this.cadastroService.checkDocument(this.fg.controls.cpf.value, this.account.accountId).then(
      (res: CheckDocumentResponse)=>{
        if(res.status){
          this.handleData(res);
        }else if(res.message){
          this.snack.open(res.message, 'ok', { duration: 5000, panelClass: this.className });
          this.router.navigate(['/login'], {
            queryParamsHandling: 'preserve',
          });
        }
        this.snack.open(res.message || 'Realize o cadastro para continuar', 'ok', { duration: 5000, panelClass: this.className });
        this.isLoading = false;
        this.cpfChecked = false;
        this.fg.get('dataNasc').clearValidators();
        this.fg.get('dataNasc').updateValueAndValidity();
      }
    ).catch(      
      (err: HttpErrorResponse)=>{
        this.slackService.slackTest(err);
        this.snack.open('Erro ao verificar cpf, tente novamente', 'ok', {duration: 3000, panelClass: this.className});

        this.isLoading = false;
      }
    );
  }

  private handleData(res:CheckDocumentResponse){

    this.fg.controls.cpf.disable();
    for(let key in res.patient){

      const value = res.patient[key];
      if(!value) continue;

      this.fg.get(key).setValue(value);
      this.fg.get(key).disable();
    }
  }

  public cadastra() {
    if (!this.fg.valid) {
      this.snack.open('Favor preencher os campos obrigatórios', '', {
        duration: 5000,
        panelClass: this.className
      });
      return;
    }

    this.isLoading = true;

    if(!this.account && !this.account.accountId){
      this.snack.open('Aconteceu um erro, por favor tente novamente ou recarregue a página', 'ok', {
        duration: 5000,
        panelClass: this.className
      });
      this.isLoading = false;
      return;
    }

    const request: ICadastroRequest = {
      accountId: this.account.accountId,
      name: this.fg.controls.nome.value.trim(),
      cpf: this.fg.controls.cpf.value,
      password: this.fg.controls.senha.value,
      gender: this.fg.controls.gender.value,
      mobileNumber: this.fg.controls.celular.value,
      mobileNumberDDI: this.fg.controls.celularDDI.value,
      birthDate: this.fg.controls.dataNasc.value,
      email: this.fg.controls.email.value.trim(),
      privacyTerm: this.fg.controls.privacyTerm.value,
    };

    if (this.convenioSelecionado) {
      request.healthPlanId = this.convenioSelecionado.healthPlan.healthPlanId;
      request.planId = this.convenioSelecionado.healthPlan.id;
      request.company = this.convenioSelecionado.company;
      request.cardNumber = this.convenioSelecionado.cardNumber;
      request.validity = this.convenioSelecionado.validity;
    }
    this.cadastroService
      .cadastra(request)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.snack.open('Cadastro realizado com sucesso. Acesse seu e-mail para ativar sua conta.', 'ok', {
            duration: 8000,
            panelClass: this.className
          });

          if(this.waitingRoomHash){
            this.goBackToWaitingRoom();
            return;
          }

          LocalStorageUtil.setCpf(this.fg.controls.cpf.value);

          if(this.data)
            this.onCloseComponent.emit(true);
          else
            this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });

          this.isLoading = false;
        },
        (error) => {
          if (error.error.statusCode == 400) {
            this.snack.open('Dados estão incorretos', '', { duration: 5000, panelClass: this.className });
            return;
          }

          this.snack.open(error.error.message, '', { duration: 5000, panelClass: this.className });
          
          if (error.error.statusCode == 409) {
            if(this.data)
              this.onCloseComponent.emit(true);
            else
              this.router.navigate(['/login'], {
                queryParamsHandling: 'preserve',
              });
          }
          this.isLoading = false;
        },
      );
  }

  private buildForm() {
    this.campos = {
      cpf: [Validators.required],
      nome: [Validators.required, this.nameValidator],
      dataNasc: [Validators.required, this.dateValidator],
      gender: [Validators.required],
      celular: [Validators.required],
      celularDDI: [Validators.required],
      email: [Validators.required],
      senha: [Validators.required],
      privacyTerm: [],
    };
  }

  private getConvenios() {
    this.cadastroService
      .loadHealthPlans(environment.subdomain)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((healthPlans) => {
        this.healthPlans = healthPlans;
        let index = 0;
        _.each(healthPlans, (healthPlan) => {
          this.convenios.push({
            index: index++,
            corporateName: healthPlan.corporateName,
          });
        });
      });
  }

  goTo(){
    if(this.data)
      this.onCloseComponent.emit(true);
    else{
      const uri = '/login';
      this.router.navigate([uri], { queryParamsHandling: 'preserve' });
    }
  }

  public goBackToWaitingRoom(){
    this.router.navigate(['/sala-espera/' + this.waitingRoomHash]);
  }

  public updateAcceptTerms(event: any){
    this.acceptTerms = event;
    if(event)
      this.fg.controls.privacyTerm.setValue(new Date().toISOString());
    else
      this.fg.controls.privacyTerm.setValue(null);
  }

  onPhoneInput(event: any) {
    const input = event.target;

    // Ajusta a posição do cursor
    const newCursorPosition = input.value.length;
    input.setSelectionRange(newCursorPosition, newCursorPosition);
  }
}
