<main-nav>
    <app-loading
        *ngIf="loading"
        class="p-margin-top-1 p-display-block"
    ></app-loading>
    <div class="myFilesMain" *ngIf="!loading">

        <div class="btnSpot">
          <button 
            mat-button
            type="button" 
            [ngClass]="isAmorSaude? 'btn-amorSaudeTertiary' : 'btn-secondary' "
            (click)="inputUpload.click()"
            >
            Adicionar anexo
          </button>
        </div>
        <input
            #inputUpload
            class="input-upload"
            type="file"
            (change)="onClickUpload($event)"
            accept=".pdf, .png, .jpg, .jpeg"
        />

        <div class="cards">
            <div [ngClass]="isAmorSaude? 'card amorsaude-bg-red with-shadow rounded' : 'card bg-purple with-shadow rounded' " *ngFor="let file of fileList">
                <div [ngClass]="isAmorSaude? 'amorsaudeColor date': 'purple date'">{{file.createdAt | date: 'DD/MM/YYYY HH:mm'}}</div>
                <div [ngClass]="isAmorSaude? 'lineBgAmorSaude line': 'lineBgPurple line'"></div>
                <a [href]="addTag(file)" target="_blank" [ngClass]="isAmorSaude? 'amorsaudeColor': 'purple' "><b>{{file.originalName}}</b></a>
            </div><br>
        </div>

    </div>
</main-nav>
