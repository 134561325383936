import { ISchedulerResponse } from '../pages/perfil/scheduler.interface';
import { IPatientResponse } from './../pages/perfil/perfil.interface';
import * as moment from 'moment';
export interface AccountInfo {
  accountId: number;
  accountName: string;
  accountEmail: string;
  responsibleId: number;
  daysBeforeSchedule: number;
  MinimumPrecedenceScheduleTime: number;
  TimeToCancelBeforeSchedule: number;
  timeToCancelPreSchedule: number;
  onlinePayment: boolean;
  logo: string;
  clinicName: string;
  consent?: string;
}

export namespace LocalStorageUtil {
  export function setAccountInfo(account: AccountInfo) {
    localStorage.setItem('account-info', JSON.stringify(account));
  }

  export function getAccountInfo(): AccountInfo {
    return JSON.parse(localStorage.getItem('account-info'));
  }

  export function setCpf(cpf: string) {
    localStorage.setItem('cpf', cpf);
  }

  export function getCpf(): string {
    return localStorage.getItem('cpf');
  }

  export function setToken(token: string) {
    localStorage.setItem('token', token);
  }

  export function getToken(): string {
    return localStorage.getItem('token');
  }

  export function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('patient-info');
    localStorage.removeItem('scheduler-info');
  }

  export function setPatientInfo(patient: IPatientResponse) {
    localStorage.setItem('patient-info', JSON.stringify(patient));
  }

  export function getPatientInfo(): IPatientResponse {
    return JSON.parse(localStorage.getItem('patient-info'));
  }

  export function setSchedulers(isSchedulers: boolean) {
    localStorage.setItem('schedulers', JSON.stringify(isSchedulers));
  }

  export function getSchedulers(): boolean {
    return JSON.parse(localStorage.getItem('schedulers'));
  }

  export function setSchedulerInfo(scheduler: ISchedulerResponse) {
    localStorage.setItem('scheduler-info', JSON.stringify(scheduler));
  }

  export function getSchedulerInfo(): ISchedulerResponse {
    return JSON.parse(localStorage.getItem('schedulers-info'));
  }
}
