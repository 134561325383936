import { animate, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { IAgendamentoSearch } from 'src/app/pages/agendamentos/agendamentos.interfaces';
import { LoginService } from 'src/app/services/login.service';
import { PacienteService } from 'src/app/services/paciente.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SlackService } from 'src/app/services/slack.service';
import { VoucherService } from 'src/app/services/voucher.service';
import { COMMUNICARE_API_MESSAGE } from 'src/app/util/constants';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { ConsentDialogComponent } from 'src/app/shared/components/consent-dialog/consent-dialog.component';

@Component({
  selector: 'agendamento-pre-confirmacao',
  templateUrl: './pre-confirmacao.component.html',
  styleUrls: ['./pre-confirmacao.component.scss'],
  animations:[
    trigger('fadeSlide',[
      transition(':enter',[
        style({ opacity:0, transform: 'translateY(10px)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})

export class PreConfirmacaoComponent implements OnInit {
  @Input() step: number;
  @Input() error: string;
  @Input() search: IAgendamentoSearch;
  @Input() isAmorSaude: any = false;
  @Output() settingVoucher = new EventEmitter<Object>();
  @Output() consentCheckbox = new EventEmitter<Object>();

  loadingVoucher = false;
  applied = false;
  isStaging = false;
  hasVouchers = false;
  isSchedulersView = false;
  isTeleconsultationProcedure = false;
  voucherCode = new FormControl('');
  observacoes = new FormControl('');
  errorMsg = '';
  originalValue = '';
  discount = '';
  discountedValue = '';
  fileName = '';
  codeLength: number = 0;
  maxCodeLength: number = 16;
  accountInfo;
  consentStatus = false;

  constructor(
    private snack: MatSnackBar,
    private voucherService: VoucherService,
    private loginService: LoginService,
    private patientService: PacienteService,
    private slackService: SlackService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ){ 
    this.isStaging = loginService.isStaging();
    this.accountInfo = LocalStorageUtil.getAccountInfo();
    this.isSchedulersView = loginService.isSchedulersView();

    voucherService.checkIfAccountHasActiveVouchers('voucher').subscribe({
      next: res => {
        this.hasVouchers = res.hasVouchers;
      },
      error: err =>{
        console.log(err)
      }
    })
  }

  public ngOnInit() {
    window.scrollTo(0, 0);
    this.originalValue = this.search.procedure.value;
    this.isTeleconsultationProcedure = !!this.search.procedure.teleconsulta;


    this.route.queryParams.subscribe(p => {
      if(p.voucher){
        this.voucherService.getVoucherCode(p.voucher).subscribe(
          res=>{
            this.voucherCode.setValue(res.code);
            this.onApplyVoucher();
          },
          err=>{
            this.snack.open('Erro ao pegar dados do voucher', 'ok', {duration: 3000});
          }
        )
      }
    })
  }

  public getMapView(item) {
    const { address, number, zipcode } = item;
    if (!address || !number || !zipcode) {
      return;
    }
    return `https://www.google.com/maps/search/?api=1&query=${address}, ${number}, ${zipcode}`;
  }

  public photoError(item: any) {
    item.professionalPhoto = null;
  }

  onChangeCode(e: any) {
    this.codeLength = e.target.value.length;
  }

  omitCharacters(event) {
    const k = event.charCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  onApplyVoucher(){
    if(!this.voucherCode.value){
      this.voucherCode.setErrors({
        'incorrect': true
      })
      return;
    }
    this.loadingVoucher = true;
    this.settingVoucher.emit({setVoucher: true});

    this.voucherService.verifyAndApplyCode(this.voucherCode.value, this.search.procedure).subscribe(
      (res: { discount: number, discountedValue: number, voucherId:number})=>{
        this.discount = res.discount.toFixed(2);
        this.discountedValue = res.discountedValue.toFixed(2);
        this.search.price = res.discountedValue.toFixed(2);
        this.search.voucherId = res.voucherId;
        this.loadingVoucher = false
        this.applied = true;
        this.settingVoucher.emit({setVoucher: false, search: this.search });
        this.voucherCode.disable();
      },
      (err: HttpErrorResponse) =>{
        if(err.status !== 500){
          this.voucherCode.setErrors({
            'httpException': true
          })
          this.errorMsg = 'Voucher inválido. Desconto não habilitado.';
        }
        this.settingVoucher.emit({setVoucher: false });
        this.loadingVoucher = false;
        console.log(err)
      }
    )
  }

  onClickUpload(event) {
    let fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);
      this.fileName = file.name;
      this.snack.open('Iniciando upload, aguarde...', 'ok', { duration: 8000 });
      this.patientService.uploadFile(formData, this.search.paciente).subscribe(
        async (r) => {

          const url = COMMUNICARE_API_MESSAGE + '/download/patient-attachment/' + r.name;

          const observation = await this.createElement(r, url);

          this.patientService.saveScheduleObservations(this.search.preScheduledId, observation).subscribe({
            next: res =>{
              this.snack.open( 'Arquivo anexado com sucesso. Confirme o agendamento para finalizar', 'Ok',{ duration: 7000  } );
            },
            error: err=>{
              this.slackService.slackTest(err);
              this.snack.open(
                'Erro ao salvar nas observações do agendamento. Arquivo salvo na listagem de anexos do paciente apenas',
                'Ok',
                {
                  duration: 8000
                }
              );
            }
          })

        },
        (err) => {
          this.slackService.slackTest(err);
          this.snack.open(
            'Erro ao realizar upload! Tente novamente, por favor.',
            'Ok',
            {
              duration: 5000
            }
          );
        }
      );
    }

  }

  private async createElement(r:any, url: string): Promise<string>{
    let elementHtml = '';
    if(this.isImage(r.originalName))
      elementHtml += `<figure class="image ck-widget" contenteditable="false"><img src="${url}"><div class="ck ck-reset_all ck-widget__type-around"><div class="ck ck-widget__type-around__button ck-widget__type-around__button_before" title="${r.originalName}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"><path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path></svg></div><div class="ck ck-widget__type-around__button ck-widget__type-around__button_after" title="Inserir parágrafo após o bloco"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8"><path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path></svg></div><div class="ck ck-widget__type-around__fake-caret"></div></div></figure>`
    else
      elementHtml += `<p><a href="${url}" class="ck-link_selected">${r.originalName}</a></p>`

    return elementHtml
  }

  private isImage(fileName: string) {
    return (
      fileName.indexOf('.jpeg') > -1 ||
      fileName.indexOf('.JPEG') > -1 ||
      fileName.indexOf('.jpg') > -1 ||
      fileName.indexOf('.JPG') > -1 ||
      fileName.indexOf('.png') > -1 ||
      fileName.indexOf('.PNG') > -1
    );
  }

  public async copyPaymentLink(){

    if(!this.search?.paymentLink){
      this.snack.open('Link não encontrado', 'ok', {
        duration: 4000
      });
      return;
    }

    await this.paymentService.copyCodeToClipboard(this.search.paymentLink).then(()=>{
      this.snack.open('O link foi copiado com sucesso', 'ok', {
        duration: 4000
      });

    }).catch(
      ()=>{
        this.snack.open('Erro ao copiar link de pagamento', 'ok', {
          duration: 4000
        })

      }
    );
  }

  onChangeConsentCheckbox(event: any){
    this.consentCheckbox.emit({consent: event.checked, date: event.checked? new Date().toISOString() : null})
  }

  openConsentDialog(){
    const dialogRef = this.dialog.open(ConsentDialogComponent, {
      position: { top: '50px' },
      autoFocus: true,
      data: {
        search: this.search
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.consentStatus = true;
        this.consentCheckbox.emit({
          consent: true, 
          date: new Date().toISOString()
        });
      }else{
        this.consentStatus = false;
        this.consentCheckbox.emit({
          consent: false,
          date: null
        });
      }
    });
  }
}
