<p 
  *ngIf="timeText" 
  [class]="(isAmorSaude? 'customColor' : 'defaultColor') + ' date-label'"
>
  Buscando horários em <span class="date-text">{{timeText}}</span>
</p>
<div
  [class]="'lds-spinner lds-spinner-' + (type || 'dark')"
  [style]="'transform: scale(' + scale + ')'"
>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
