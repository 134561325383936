<div class="survey-box" [ngStyle]="style?.box && style?.box" @fadeInOut>
    <div class="survey-box-body" [ngStyle]="style?.body && style?.body">
        <div class="survey-box-body-buttons-container">
            <button
                mat-mini-fab
                (click)="backSurvey()"
                class="btn-back"
                matTooltip="Voltar"
                [ngStyle]="{'visibility': showBackButton ? 'visible' : 'hidden'}"
            >
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <button
                mat-mini-fab
                (click)="closeSurvey()"
                class="btn-close"
                matTooltip="Fechar"
                [ngStyle]="{'visibility': showCloseButton ? 'visible' : 'hidden'}"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div class="survey-box-body-content">
            <ng-content></ng-content>
        </div>
    </div>
</div> 