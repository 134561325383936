import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { IPatientResponse } from 'src/app/pages/perfil/perfil.interface';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';

@Component({
  selector: 'app-consent-dialog',
  templateUrl: './consent-dialog.component.html',
  styleUrls: ['./consent-dialog.component.scss']
})
export class ConsentDialogComponent {
  consentText: string;
  patientInfo: IPatientResponse;
  loading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ConsentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.patientInfo = LocalStorageUtil.getPatientInfo();
    this.loadConsentText();
  }

  async loadConsentText(){
    this.loading = true;
    const accountInfo = LocalStorageUtil.getAccountInfo();
    await this.changeDinamicData(accountInfo.consent || 'Termo de consentimento não encontrado');
    this.loading = false;
  }

  async changeDinamicData(consent: string){
    this.consentText = consent;
    const age: number = new Date().getFullYear() - Number(this.patientInfo.birthDate.slice(4));

    this.consentText = this.consentText.replace('%NOME%', this.patientInfo.name.split(' ')[0]);
    this.consentText = this.consentText.replace('%NOMECOMPLETO%', this.patientInfo.name);
    this.consentText = this.consentText.replace('%NASCIMENTO%', moment(this.patientInfo.birthDate, 'DDMMYYYY').format('DD/MM/YYYY'));
    this.consentText = this.consentText.replace('%IDADE%', age + '');
    this.consentText = this.consentText.replace('%CPF%', this.patientInfo.cpf);

    this.consentText = this.consentText.replace('%HOJE%', moment().format('DD/MM/YYYY'));
    this.consentText = this.consentText.replace('%PROFISSIONAL%', this.data.search.treatmentPronoun? this.data.search.treatmentPronoun + ' ' + this.data.search.professional : this.data.search.professional);
    this.consentText = this.consentText.replace('%CRM%', this.data.search.document);
    this.consentText = this.consentText.replace('%CAMPOPERSONALIZADO%', this.patientInfo?.fastObservation || '');
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onAccept(): void {
    this.dialogRef.close(true);
  }
} 