import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { SurveyControlService } from 'src/app/services/survey-control.service';
import { SurveyNpsDto } from './survey-nps.interface';
import { SurveyControlResponse } from 'src/app/models/survey-control.interface';

export interface SurveyBoxShow {
  buttons: {
    close: boolean;
    back: boolean;
  };
}

@Component({
  selector: 'app-survey-nps',
  templateUrl: './survey-nps.component.html',
  styleUrls: ['./survey-nps.component.scss'],
  animations: [
    trigger('stepTransition', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SurveyNpsComponent implements OnInit {
  // @Output() close: EventEmitter<any> = new EventEmitter();
  // @Output() back: EventEmitter<boolean> = new EventEmitter();

  form: FormGroup = new FormGroup({
    score: new FormControl(),
    comment: new FormControl()
  });

  public showConfig: SurveyBoxShow = {
    buttons: {
      close: false,
      back: false,
    }
  };

  public step: number = 1;
  public firstStep: number = 1;
  public lastStep: number = 5;
  public backStep: number = 1;

  public steps = {
    1: {
      title: 'Baseado no atendimento que acabou de receber. Qual a probabilidade de recomendar nosso serviço a amigos ou colegas?',
    },
    2: {
      title: 'Estamos tristes em ouvir isso. O que poderíamos ter feito diferente para melhorar sua experiência?',
    },
    3: {
      title: 'Qual é a coisa mais importante que poderíamos fazer para melhorar sua experiência no futuro?',
    },
    4: {
      title: 'Que ótimo ouvir isso! O que você mais gostou nesse atendimento?',
    },
    5: {
      title: '😀 Obrigado pela sua resposta!\nSua opinião é muito importante para a gente.',
    },
  };

  public scoreList: Array<number> = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
  ];

  public scoreStep = {
    0: 2,
    1: 2,
    2: 2,
    3: 2,
    4: 2,
    5: 2,
    6: 2,
    7: 3,
    8: 3,
    9: 4,
    10: 4,
  };

  public loading: boolean = false;
  public scheduleId: number;
  private surveyControl:SurveyControlResponse;
  public showNps: boolean = false;
  public showExampleNps: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private surveyControlService: SurveyControlService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
        const scheduleId = params['scheduleId'];
        if(scheduleId == 'exampleId')
          return this.showExampleNps = true;
        if (scheduleId && !isNaN(Number(scheduleId))) {
            this.scheduleId = Number(scheduleId);
        } else {
            console.error('Invalid or missing schedule ID parameter');
        }
    });

    this.init();
  }

  private async init() {
    this.loading = true;
    await this.getSurveyNpsByScheduleId();
    this.setShowNps();
    this.loading = false;
  }

  closeSurvey() {
    console.log('closeSurvey');
    // this.close.emit(false);
  }

  backSurvey() {
    this.step = this.backStep;
    this.resetForm();
    this.setShowConfig();
  }

  private resetForm() {
    this.form.reset();
  }

  selectScore(score: number) {
    this.setScore(score);
    this.setStep();
    this.setShowConfig();
  }

  private setScore(score: number) {
    this.form.get('score')?.setValue(score);
  }

  // public send() {
  //   this.setStep(true);
  //   this.setShowConfig();
  //   const formData = this.form.getRawValue();
  //   this.close.emit(formData);
  // }

  public async create() {
    try {
      const formData = this.form.getRawValue();
      this.setStep(true);
      this.setShowConfig();
      const response = await this.createSurveyNps(formData);
    } catch (error) {
      console.log(error);
    }
  }
  
  private async createSurveyNps(surveyNpsDto?:SurveyNpsDto):Promise<any> {
    try {
      if (!surveyNpsDto) return null;
      if (typeof surveyNpsDto != 'object') return null;
      if (Array.isArray(surveyNpsDto)) return null;
      if (surveyNpsDto.score == null) return null;

      const body:SurveyNpsDto = JSON.parse(JSON.stringify(surveyNpsDto));
      body.scheduleId = body.scheduleId || this.scheduleId;
      body.surveyControlId = this.surveyControl?.surveyControlId || null;

      const response = await this.surveyControlService.createSurveyNps(body).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  private setStep(isLastStep: boolean = false) {
    const score = this.form.get('score')?.value;
    if (isLastStep) return this.step = this.lastStep;
    if (score == null) return this.step = this.firstStep;
    this.step = this.scoreStep[score] || this.firstStep;
  }

  private setShowConfig() {
    if (this.step > this.firstStep && this.step < this.lastStep) {
      this.showConfig.buttons.back = true;
    } else {
      this.showConfig.buttons.back = false;
    }
  }

  refreshScreen() {
    window.location.reload();
  }

  async getSurveyNpsByScheduleId() {
    try {
      if (!this.scheduleId) return null;
      const response = await this.surveyControlService.getSurveyNpsByScheduleId(this.scheduleId).toPromise();
      this.surveyControl = response || null;
    } catch (error) {
      console.log(error);
    }
  }

  public setShowNps() {

    if (
      this.surveyControl?.isSurveySendingEnabled
    ) {
      this.showNps = true;
    } else {
      this.showNps = false;
    }
  }
} 